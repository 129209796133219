import {CarBrandCard, Carousel, Heading} from '~/components';
import {SanitySectionCarBrands} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function CarBrands({
  section,
}: {
  section: SerializeFrom<SanitySectionCarBrands>;
}) {
  const {title, selected} = section;
  return (
    <section className="relative flex w-full flex-col overflow-hidden text-white">
      <div className="section-px mx-auto w-full max-w-site py-20 pb-20">
        <Heading
          size={'heading'}
          className="mx-auto mb-12 text-center uppercase"
        >
          {title}
        </Heading>

        <div className="flex flex-col flex-wrap items-center justify-center gap-12 md:flex-row lg:justify-between">
          {selected?.map((carBrand) => (
            <CarBrandCard key={carBrand._id} carBrand={carBrand} />
          ))}
        </div>
      </div>
    </section>
  );
}
