/* eslint-disable eslint-comments/disable-enable-pair */
declare global {
  interface Window {
    _learnq: {
      push: (
        args:
          | [string, Record<string, any>]
          | [string, string, Record<string, any>],
      ) => void;
    };
  }
}

function viewedProduct(payload: {
  Name?: string;
  ProductID?: string;
  Categories?: string[];
  ImageURL?: string;
  URL?: string;
  Brand?: string;
  Price?: string;
  CompareAtPrice?: string;
}) {
  if (!window._learnq) return;
  window._learnq.push(['track', 'Viewed Product', payload]);
}

function viewedItem(payload: {
  Title?: string;
  ItemId?: string;
  Categories?: string[];
  ImageUrl?: string;
  Url?: string;
  Metadata?: {
    Brand?: string;
    Price?: number;
    CompareAtPrice?: number;
  };
}) {
  if (!window._learnq) return;
  window._learnq.push(['trackViewedItem', payload]);
}

function addToCart(cart: {
  total_price?: string;
  $value?: string;
  original_total_price?: string;
  items?: any;
}) {
  if (!window._learnq) return;
  window._learnq.push(['track', 'Added to Cart', cart]);
}

function identifyCustomer(user: {
  $email?: string;
  $first_name?: string;
  $last_name?: string;
}) {
  if (!window._learnq) return;
  window._learnq.push(['identify', user]);
}

export const learnq = {
  viewedProduct,
  viewedItem,
  addToCart,
  identifyCustomer,
};

export function KlaviyoTrack({klaviyoCompanyid}: {klaviyoCompanyid: string}) {
  if (!klaviyoCompanyid) return null;

  return (
    <>
      <script
        async
        src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoCompanyid}`}
      />
    </>
  );
}
