import {IconClose, Link} from '~/components';

export function Modal({
  children,
  cancelLink,
}: {
  children: React.ReactNode;
  cancelLink: string;
}) {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      id="modal-bg"
    >
      <div className="fadeIn fixed bottom-0 left-0 right-0 top-nav z-50 transform-gpu backdrop-blur">
        <div className="absolute inset-0 bg-black/50"></div>
        <div className="absolute inset-0 items-center justify-center overflow-y-auto md:flex">
          <Link to={cancelLink} className="absolute inset-0 block" />
          <div className="flex transform flex-col  bg-black p-6 text-left shadow-xl transition-all sm:w-full sm:max-w-3xl sm:flex-none sm:p-12 md:rounded-lg md:border md:border-white lg:top-auto ">
            <Link
              to={cancelLink}
              className="absolute right-0 top-0 p-4 text-white transition hover:text-white/50"
            >
              <IconClose aria-label="Close panel" />
            </Link>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
