import {
  Heading,
  Button,
  SanityImage,
  SanityMuxVideo,
  ImageWithProductHotspots,
  Brandmark,
} from '~/components';
import {SanitySectionSnapshot, SanityShopifyProducts} from '~/types';
import type {SerializeFrom} from '@shopify/remix-oxygen';

export function Snapshot({
  section,
  shopifyProducts,
}: {
  section: SerializeFrom<SanitySectionSnapshot>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
}) {
  const {components} = section;

  const media = (module: any) => {
    switch (module?._type) {
      case 'image':
        return (
          <SanityImage
            crop={module?.crop}
            hotspot={module?.hotspot}
            width={1000}
            height={1000}
            objectFit="cover"
            layout="fill"
            sizes={[600, 1000]}
            src={module?.asset?._ref}
          />
        );

      case 'module.video': {
        return <SanityMuxVideo video={module} />;
      }
    }
  };

  return (
    <section className="relative flex w-full flex-col justify-end">
      <div className="z-10">
        <div className="grid grid-cols-1 md:grid-cols-2">
          {components?.map((component) => {
            return (
              <div key={component._key} className="flex flex-col gap-4">
                {component._type === 'banner' && (
                  <div
                    className="relative flex aspect-square w-full flex-col justify-end"
                    style={{
                      background: component?.theme?.background?.hex,
                      color: component?.theme?.text?.hex,
                    }}
                  >
                    <div className="absolute inset-0 overflow-clip">
                      {media(component.media)}
                    </div>
                    <div className="section-px z-10 flex h-full flex-col  justify-between py-10">
                      <Brandmark className="ml-auto hidden h-auto w-[6rem] max-w-full text-white/40 md:block" />
                      <div
                        className={
                          'mr-0 flex max-w-siteHalf flex-col items-start justify-between gap-10'
                        }
                      >
                        <div className="flex flex-col gap-10 after:h-[2px] after:w-20 after:bg-offWhite/60">
                          {component.title && (
                            <Heading
                              size="displayLg"
                              as="h2"
                              className="uppercase text-silk"
                            >
                              {component.title}
                            </Heading>
                          )}
                        </div>
                        {component.button && (
                          <Button
                            to={component.button.link?.to}
                            type={component.button.link?._type}
                            variant={component.button.variant}
                            width="auto"
                          >
                            {component.button?.link?.title}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {component._type === 'imageWithProductHotspots' && (
                  <div className="relative flex aspect-square w-full flex-col md:aspect-auto md:h-full">
                    <ImageWithProductHotspots
                      content={component}
                      shopifyProducts={shopifyProducts}
                    />
                  </div>
                )}
                {component._type === 'media' && (
                  <div className="relative flex aspect-square w-full flex-col justify-end">
                    <div className="absolute inset-0 overflow-clip">
                      {media(component.media)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
