import clsx from 'clsx';

export function Brandmark({
  className,
  fill = 'currentColor',
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 600 600"
      fill="none"
      className={clsx(className)}
      preserveAspectRatio="xMinYMid meet"
    >
      <path fill={fill} d="M191.1 346.8c27.7 6 55.7 10.5 84 13.8l6.4-47.7-165-51.5 172.4 15 13.4-48.3c-15.5-2.2-30.5-4.4-45-6.7-20.9-3.3-39.3-7.5-55.1-12.5-15.8-5-28.4-11.2-37.6-18.6-9.2-7.4-13.9-16.8-13.9-28.2 0-13.3 4.2-23.9 12.5-31.7 8.3-7.9 18.8-13.8 31.3-17.8s25.9-6.6 40.3-7.9c14.3-1.2 27.5-1.8 39.4-1.8 19.3 0 40.2.6 62 2.2l22-79.6c-23.4-3.7-48.6-5.8-75.8-5.8-63.4 0-119.1 11.7-147.8 20C106.1 48.1 82.5 59.1 64 73c-18.5 13.8-32 29.8-40.3 47.8-8.3 18-12.5 37.1-12.5 57.1 0 48 14.4 85 43.5 110.8 28.9 25.9 74.4 45.2 136.5 58.1zm394.8-131c0-70.8-73.2-164.4-223.1-189.5L352 106.5c88.9 8.5 187.4 35.4 233.9 109.3zm-121.8 42c-22.7-6.2-46.1-11.4-70.3-15.7-20-3.5-39.6-6.6-58.9-9.3l-7 51.4 165 51.5-172.4-15-12.2 43.8c22.4 2.8 43.1 6.1 62.2 9.8 25.3 5 46.2 11.6 62.3 20 16.1 8.3 24.1 20.6 24.1 36.7 0 15.3-3.7 27.5-11.1 37.1-7.4 9.5-17.6 16.9-30.4 22.1-12.8 5.2-27.7 8.9-44.8 10.7-17 1.9-34.8 2.9-53.3 2.9-11.5 0-27.7-.9-46.6-2.9l-19.9 72.3c28.6 4.4 59.9 6.9 94.3 6.9 36.9 0 71.2-3.7 102.5-11.1 31.3-7.4 58.3-18.1 81-32.4 22.6-14.3 40.3-32.1 52.8-53.5 12.5-21.3 18.8-46.1 18.8-74.1 0-40.9-11.9-73.2-35.8-97-23.9-23.8-57.3-41.8-100.2-54.2h-.1zM0 341.8c-2 87.1 62.6 200.8 246.4 230.7l9.9-73.3C164.1 487 22 447.3 0 341.8z" />
    </svg>
  );
}
