import {CustomCarBrandCard, Heading} from '~/components';
import {SanityCarBrand} from '~/types';
import type {SerializeFrom} from '@shopify/remix-oxygen';

export function SelectCarBrand({
  section,
}: {
  section: SerializeFrom<SanityCarBrand[]>;
}) {
  return (
    <section className="relative  hidden w-full flex-col overflow-hidden text-white md:flex">
      <div className="section-px mx-auto w-full max-w-site py-20 pb-20">
        <Heading
          size={'heading'}
          className="mx-auto mb-12 text-center uppercase"
        >
          SELECT YOUR SUPERCAR BRAND TO FIND OUT MORE
        </Heading>

        <div className="flex flex-col flex-wrap items-center justify-center gap-y-8 md:flex-row">
          {section?.map((carBrand) => (
            <CustomCarBrandCard key={carBrand._id} carBrand={carBrand} />
          ))}
        </div>
      </div>
    </section>
  );
}
