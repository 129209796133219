import {Button, Grid, ProductCard, Link} from '~/components';
import {getImageLoadingPriority} from '~/lib/const';
import type {Collection, Product} from '@shopify/hydrogen/storefront-api-types';
import {useFetcher} from '@remix-run/react';
import {useEffect, useState} from 'react';
import {useInView} from 'react-intersection-observer';

export function ProductGrid({
  url,
  collection,
  ...props
}: {
  url: string;
  collection: Collection;
}) {
  const [initialProducts, setInitialProducts] = useState(
    collection?.products?.nodes || [],
  );

  const [nextPage, setNextPage] = useState(
    collection?.products?.pageInfo?.hasNextPage,
  );
  const [endCursor, setEndCursor] = useState(
    collection?.products?.pageInfo?.endCursor,
  );
  const [products, setProducts] = useState(initialProducts);

  const {ref: nextLinkRef, inView: nextInView} = useInView();

  // props have changes, reset component state
  const productProps = collection?.products?.nodes || [];
  if (initialProducts !== productProps) {
    setInitialProducts(productProps);
    setProducts(productProps);
  }

  const fetcher = useFetcher();

  function fetchMoreProducts() {
    fetcher.load(`${url}?index&cursor=${endCursor}`);
  }

  useEffect(() => {
    if (!fetcher.data) return;

    const {collection} = fetcher.data;

    setProducts((prev: Product[]) => [
      ...prev,
      ...(collection?.products.nodes || []),
    ]);
    setNextPage(collection?.products?.pageInfo.hasNextPage);
    setEndCursor(collection?.products?.pageInfo.endCursor);
  }, [fetcher.data]);

  // auto load more products when user scrolls to the next link
  useEffect(() => {
    if (fetcher.state !== 'idle') return;
    if (!nextPage) return;
    if (!nextInView) return;
    fetchMoreProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextInView, fetcher.state, nextPage]);

  const haveProducts = initialProducts.length > 0;

  if (!haveProducts) {
    return (
      <>
        <p>No products found on this collection</p>
        <Link to="/products">
          <p className="underline">Browse catalog</p>
        </Link>
      </>
    );
  }

  return (
    <>
      <Grid layout="products" gap="products" {...props}>
        {products.map((product, i) => (
          <div
            key={product.id}
            // className={(i + 1) % 3 === 0 ? 'col-span-2 sm:col-span-1' : ''}
            className={'col-span-2 sm:col-span-1'}
          >
            <ProductCard
              product={product}
              loading={getImageLoadingPriority(i)}
            />
          </div>
        ))}
      </Grid>

      {nextPage && (
        <div className="mt-6 flex items-center justify-center">
          <Button
            ref={nextLinkRef}
            disabled={fetcher.state !== 'idle'}
            variant="dark"
            onClick={fetchMoreProducts}
            width="full"
            prefetch="intent"
          >
            {fetcher.state !== 'idle' ? 'Loading...' : 'Load more products'}
          </Button>
        </div>
      )}
    </>
  );
}
