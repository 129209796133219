import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCirclePlay} from '@fortawesome/sharp-solid-svg-icons';
import MuxVideo from '@mux/mux-video-react';
import imageUrlBuilder from '@sanity/image-url';
import clsx from 'clsx';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useWindowSize} from 'react-use';
import useMuxPoster from '~/hooks/useMuxPoster';
import {SanityVideo} from '~/types';
import sanityConfig from '../../../sanity.config';
import {SanityImage} from './SanityImage';

type Props = {
  video: SanityVideo;
  isPlaying?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  setMediaActive?: React.Dispatch<React.SetStateAction<boolean>>;
  [key: string]: any;
};

export function SanityMuxVideo({
  video,
  isPlaying = false,
  onPlay = () => {},
  onPause = () => {},
  onEnded = () => {},
  setMediaActive = () => {},
  className,
  ...props
}: Props) {
  const {asset, thumbnail, options} = video;
  const {projectId = '', dataset = ''} = sanityConfig;
  const window = useWindowSize();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [activePlaybackId, setActivePlaybackId] = useState<string | null>(
    asset?.playbackId,
  );
  const [activeThumbnailId, setActiveThumbnailId] = useState<string | null>(
    thumbnail ?? null,
  );

  const muxPoster = useMuxPoster({
    playbackId: asset?.playbackId,
    options: {width: props?.width || 1920, height: props?.height || 1080},
  });

  const poster = useMemo(() => {
    return (
      (thumbnail &&
        imageUrlBuilder({projectId, dataset})
          .image(thumbnail || '')
          .width(props?.width || 1920)
          .height(props?.height || 1080)
          .url()) ||
      muxPoster
    );
  }, [muxPoster, thumbnail, projectId, dataset, props.width, props.height]);

  const handleOverlayPlay = () => {
    if (options?.overlay && videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        onPause();
      } else {
        videoRef.current.play();
        onPlay();
      }
      setMediaActive(!isPlaying);
    }
  };

  const handleVideoEnded = () => {
    onEnded();
    if (!options?.loop && videoRef.current) {
      videoRef.current.currentTime = 0;
      onPause();
      setMediaActive(false);
    }
  };

  const handleVideoRefChange = useCallback((node) => {
    videoRef.current = node;
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (window.width < 768 && video.assetMobile) {
        setActivePlaybackId(video.assetMobile.playbackId);
        setActiveThumbnailId(video.thumbnailMobile);
        videoRef.current.pause();
        onPause();
        setMediaActive(false);
      } else {
        setActivePlaybackId(video.asset.playbackId);
        setActiveThumbnailId(thumbnail);
        videoRef.current.pause();
        onPause();
        setMediaActive(false);
      }
    }
  }, [window.width, video, onPause, setMediaActive, thumbnail]);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
        onPlay();
      } else {
        videoRef.current.pause();
        onPause();
      }
    }
  }, [isPlaying, onPlay, onPause]);

  return (
    <div className="relative h-full w-full">
      <div
        className={clsx(
          isPlaying && 'opacity-0',
          'pointer-events-none absolute inset-0 overflow-clip transition-opacity duration-100',
        )}
      >
        <SanityImage
          crop={activeThumbnailId?.crop}
          hotspot={activeThumbnailId?.hotspot}
          width={props?.width || 1920}
          objectFit="cover"
          layout="fill"
          sizes={[1240, 1920]}
          src={activeThumbnailId?.asset?._ref}
        />
      </div>
      <MuxVideo
        ref={handleVideoRefChange}
        className={clsx(
          'h-full w-full max-w-full object-cover',
          !options?.overlay && 'pointer-events-none',
          className,
        )}
        playbackId={activePlaybackId}
        streamtype="on-demand"
        controls={false}
        autoPlay={options?.autoplay}
        playsInline={options?.autoplay}
        muted={options?.muted}
        loop={options?.loop}
        onEnded={handleVideoEnded}
      />
      {options?.overlay && (
        <div
          className={clsx(
            isPlaying && 'opacity-0',
            'absolute inset-0 h-full w-full transition-opacity duration-100 before:absolute before:inset-0 before:h-full before:w-full before:bg-dark/10',
          )}
        >
          <button
            className="absolute inset-0 z-10 transform duration-150 hover:scale-110"
            onClick={handleOverlayPlay}
          >
            <FontAwesomeIcon
              icon={faCirclePlay}
              inverse
              className="text-white"
              size={'6x'}
            />
          </button>
        </div>
      )}
    </div>
  );
}
