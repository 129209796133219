import {Footer, Header} from '~/components/layout';
import {useLocation} from '@remix-run/react';
import type {CSSProperties} from 'react';
import {getHeaderTheme} from '~/lib/utils';
import type {CookieData, ModalData, LayoutData} from '~/types';
import clsx from 'clsx';
import {NewsletterModal, EnquireModal} from '~/components';
export function Layout({
  children,
  layout,
  cookies,
  modal,
}: {
  children: React.ReactNode;
  layout: LayoutData;
  cookies: CookieData;
  modal: ModalData;
}) {
  const location = useLocation();
  const pathname = location.pathname ?? '/';
  const headerTheme = getHeaderTheme(pathname);
  return (
    <div id="varUpdate" style={updateRootStyleVariables(layout)}>
      <div className="flex min-h-screen flex-col">
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <Header
          menus={layout?.menus}
          theme={headerTheme}
          siteBanner={layout?.siteBanner}
        />
        <main
          role="main"
          id="mainContent"
          className={clsx('flex-grow', {
            '-mt-nav': headerTheme === 'transparent',
          })}
        >
          {children}
        </main>
      </div>
      <Footer
        menus={layout?.menus}
        social={layout?.social}
        contact={layout?.contact}
      />
      <NewsletterModal {...layout?.newsletterModal} cookies={cookies} />
      <EnquireModal modal={modal} />
    </div>
  );
}

function updateRootStyleVariables(layout: LayoutData) {
  const styleObejct: Record<string, string> = {};

  if (layout?.siteBanner?.active) {
    styleObejct['--height-nav'] = 'var(--height-nav-with-banner)';
  }

  if (Object.keys(styleObejct).length === 0) {
    return undefined;
  }

  return styleObejct as CSSProperties;
}
