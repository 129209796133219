import {Link} from '~/components';
import {SanityLink, SanityMenu} from '~/types';

export function LegalMenu({menu}: {menu?: SanityMenu}) {
  return (
    <nav className="mb-3 flex flex-wrap items-baseline gap-6 text-p3 lg:mb-0 lg:justify-center">
      {(menu?.links || []).map((item: SanityLink) => (
        <Link
          key={item._key}
          to={item.to}
          target={item.target}
          prefetch="intent"
        >
          <span className="roll roll-gray hover:roll-activate block">
            {item.title}
          </span>
        </Link>
      ))}
    </nav>
  );
}
