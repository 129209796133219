import clsx from 'clsx';
import {Badge} from '~/types';

export function Labels({
  labels,
  className,
}: {
  labels?: Badge[];
  className?: string;
}) {
  const renderedLabels: any[] = [];

  const styleMap = {
    light: 'bg-white text-dark',
    dark: 'bg-dark text-white',
    olive: 'bg-olive text-silk',
    gray: 'bg-gray text-white',
  };

  labels?.map((label: Badge) => {
    renderedLabels.push(
      <Label
        key={label._key}
        label={label.title}
        className={styleMap[label.style]}
      />,
    );
  });

  if (renderedLabels?.length === 0) return null;

  return <div className={className}>{renderedLabels}</div>;
}

function Label({label, className}: {label: string; className?: string}) {
  return (
    <label
      className={clsx(
        'rounded-sm p-1.5 pb-1 text-right text-p2 leading-none tracking-widest',
        className,
      )}
    >
      {label}
    </label>
  );
}
