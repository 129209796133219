import {Bmas, Dennis} from '~/components';

export function SiteCredits() {
  const year = new Date().getFullYear();

  return (
    <div className="flex items-baseline gap-3 self-center pr-3 text-p2 text-spanishGray xs:text-p3">
      <span>&copy; {year} Senturion Key.</span>
      <Bmas />
      {/* <Dennis /> */}
    </div>
  );
}
