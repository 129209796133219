import {MediaFile} from '@shopify/hydrogen';
import type {
  MediaEdge,
  ProductVariant,
} from '@shopify/hydrogen/storefront-api-types';

import type {MediaImage} from '@shopify/hydrogen/storefront-api-types';
import {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';
import {useMemo} from 'react';
import {useWindowSize} from 'react-use';
import {Fancybox, SanityImage, SanityMuxVideo} from '~/components';
import {ATTR_LOADING_EAGER} from '~/lib/const';
import {SanityColorGallery, SanityMedia} from '~/types';
/**
 * A client component that defines a media gallery for hosting images, 3D models, and videos of products
 */
export function ProductGallery({
  media,
  galleries,
  selectedVariant,
}: {
  media: MediaEdge['node'][];
  galleries?: SanityColorGallery[];
  selectedVariant: SerializeFrom<ProductVariant>;
  className?: string;
}) {
  const selectedGallery = useMemo(() => {
    if (!galleries?.length) return undefined;
    const selectedColor = selectedVariant.selectedOptions?.find(
      (option) => option.name === 'Color',
    )?.value;
    const gallery = selectedColor
      ? galleries.find((gallery) => gallery.color === selectedColor)
      : galleries[0];
    return gallery?.media;
  }, [galleries, selectedVariant.selectedOptions]);

  const {width: windowWidth} = useWindowSize();

  const isLgScreen = windowWidth >= 64 * 16; // 64rem;

  return (
    <Fancybox>
      <div className="grid-col-1 grid w-full justify-center gap-5 pt-5">
        <div className="sticky top-[calc(var(--height-nav)+1.25rem)] lg:h-product-main">
          {selectedGallery?.length && (
            <GalleryMedia media={selectedGallery?.[0]} className="h-full" />
          )}

          {!selectedGallery?.length && media?.[0] && (
            <ShopifyMedia
              key={media?.[0]?.id}
              med={media?.[0]}
              className="h-full"
            />
          )}
        </div>
        {/* NOTE: DISABLED */}
        {/* <div
          className={clsx(
            'swimlane hiddenScroll aspect-[5/6] md:grid-flow-row md:overflow-x-visible',
            'h-full gap-5 md:w-full md:grid-cols-1',
          )}
        >
          {selectedGallery?.map(
            (med, i) =>
              !(i === 0 && isLgScreen) && (
                <div key={med._key} className="flex h-full">
                  <GalleryMedia
                    className={clsx(
                      'aspect-[5/6] h-full snap-center lg:w-full',
                    )}
                    media={med}
                  />
                </div>
              ),
          )}

          {!selectedGallery?.length &&
            media?.map((med, i) => (
              <ShopifyMedia
                key={med?.id}
                className={clsx('aspect-[5/6] h-full snap-center lg:w-full', {
                  'lg:hidden': i === 0,
                })}
                med={med}
              />
            ))}
        </div> */}
      </div>
    </Fancybox>
  );
}

function GalleryMedia({
  media,
  className,
}: {
  media: SanityMedia;
  className?: string;
}) {
  const med = useMemo(() => {
    switch (media._type) {
      case 'image':
        return (
          <button
            type="button"
            className="flex h-full w-full"
            data-fancybox="gallery"
            data-src={media?.url}
          >
            <SanityImage
              crop={media?.crop}
              hotspot={media?.hotspot}
              width={1600}
              height={2000}
              objectFit="cover"
              sizes={[800, 1600, 2000, 2400]}
              src={media?.asset?._ref}
              className="fadeIn bg-nero h-full w-full rounded-md object-cover object-top"
            />
          </button>
        );

      case 'video': {
        return <SanityMuxVideo video={media} className="rounded-md" />;
      }
      default:
        return null;
    }
  }, [media]);

  return <div className={clsx(className)}>{med}</div>;
}

function ShopifyMedia({
  med,
  className,
}: {
  med: MediaEdge['node'];
  className?: string;
}) {
  let mediaProps: Record<string, any> = {};

  const data = {
    ...med,
    image: {
      // @ts-ignore
      ...med.image,
      altText: med.alt || 'Product image',
    },
  } as MediaImage;

  switch (med.mediaContentType) {
    case 'IMAGE':
      mediaProps = {
        width: 1800,
        widths: [400, 800, 1200, 1800, 2000, 2400],
      };
      break;
    case 'VIDEO':
      mediaProps = {
        width: '100%',
        autoPlay: true,
        controls: false,
        muted: true,
        loop: true,
        preload: 'auto',
      };
      break;
    case 'EXTERNAL_VIDEO':
      mediaProps = {width: '100%'};
      break;
    case 'MODEL_3D':
      mediaProps = {
        width: '100%',
        interactionPromptThreshold: '0',
        ar: true,
        loading: ATTR_LOADING_EAGER,
        disableZoom: true,
      };
      break;
  }

  // if (isFirst && med.mediaContentType === 'IMAGE') {
  //   mediaProps.loading = ATTR_LOADING_EAGER;
  // }

  return (
    <button
      className={clsx(className)}
      // @ts-ignore
      key={med.id || med.image.id}
      data-fancybox="gallery"
      data-src={data.image!.url}
    >
      {/* TODO: Replace with MediaFile when it's available */}
      {/* {(med as MediaImage).image && (
        <img
          src={data.image!.url}
          alt={data.image!.altText!}
          className="fadeIn bg-nero h-full w-full rounded-md object-cover object-top"
        />
      )} */}
      <MediaFile
        tabIndex="0"
        className={`fadeIn bg-nero h-full w-full rounded-md object-cover`}
        data={data}
        sizes={'(min-width: 64em) 60vw, (min-width: 48em) 50vw, 90vw'}
        // @ts-ignore
        options={{
          crop: 'center',
          scale: 2,
        }}
        {...mediaProps}
      />
    </button>
  );
}
