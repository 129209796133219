import {useLocation, useNavigate} from '@remix-run/react';
import {useEffect, useState} from 'react';
import {Enquire, IconClose} from '~/components';
import {disableDocumentScroll, enableDocumentScroll} from '~/lib/utils';
import {ModalData} from '~/types';

export function EnquireModal({modal}: {modal: ModalData}) {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (modal === 'enquire' && !modalOpen) {
      setModalOpen(true);
      disableDocumentScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  ///close on route change
  useEffect(() => {
    if (modalOpen) {
      setModalOpen(false);
      enableDocumentScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClose = () => {
    setModalOpen(false);
    enableDocumentScroll();
    navigate(location.pathname, {replace: true});
  };

  if (!modalOpen) return null;

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      id="modal-bg"
    >
      <div className="fadeIn fixed bottom-0 left-0 right-0 top-nav z-50 transform-gpu bg-frost/60 backdrop-blur-xl">
        <div className="absolute inset-0 h-screen-no-nav overflow-y-auto">
          <div className="flex w-full justify-end">
            <button
              onClick={handleClose}
              className="p-4 text-white transition hover:text-white/50"
            >
              <IconClose aria-label="Close panel" />
            </button>
          </div>
          <div className="relative mx-auto flex w-full max-w-3xl">
            <div className="w-full p-3 py-10 text-white md:p-10 md:pt-7">
              <h2 className="mb-10 text-center text-d5 uppercase">
                Make an Enquiry
              </h2>
              <Enquire />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
