import {SanitySectionCallout} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function Callout({
  section,
}: {
  section: SerializeFrom<SanitySectionCallout>;
}) {
  const {text, subtitle} = section;

  return (
    <section className="relative my-15 flex w-full flex-col lg:my-32">
      <div className="section-px mx-auto flex max-w-site flex-col items-center gap-5 lg:gap-8 ">
        {text && (
          <p className="max-w-prose-narrow text-center text-p5 lg:text-p8">
            {text}
          </p>
        )}

        {subtitle && (
          <p className=" text-center text-p3 text-davys lg:text-p4">
            {subtitle}
          </p>
        )}
      </div>
    </section>
  );
}
