import {SanityImage} from '~/components';
import {SanityCarBrand} from '~/types';

export function CarBrandCard({carBrand}: {carBrand: SanityCarBrand}) {
  const {title, logo} = carBrand;

  return (
    <article className="">
      <div className="">
        {logo && (
          <SanityImage
            className="fadeIn backface-hidden transition-transform duration-500 "
            width={440}
            objectFit="cover"
            alt={title}
            sizes={[]}
            layout="responsive"
            src={logo?.asset?._ref}
          />
        )}
      </div>
    </article>
  );
}
