import {Heading, Button, Labels, PortableText, SanityImage} from '~/components';
import {SanitySectionMediaText} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';

export function MediaText({
  section,
}: {
  section: SerializeFrom<SanitySectionMediaText>;
}) {
  const {
    titleGroup: {title, labels, actions, description, preTitle},
    image,
    switchOrder,
  } = section;

  return (
    <section className="relative flex w-full flex-col justify-end bg-dark">
      <div className="z-10">
        <div className="mx-auto">
          <div className="flex flex-col items-center md:grid md:grid-cols-2">
            <div
              className={clsx(
                'relative aspect-[5/4] h-full w-full',
                switchOrder ? 'md:order-1' : '',
              )}
            >
              <SanityImage
                className="fadeIn"
                crop={image?.crop}
                hotspot={image?.hotspot}
                width={1250}
                objectFit="cover"
                layout="fill"
                sizes={[937, 1250, 1875]}
                src={image?.asset?._ref}
              />
            </div>

            <div className="section-px max-w-[56ch] space-y-8 py-10 md:space-y-10 md:justify-self-center md:p-10">
              {labels && <Labels labels={labels} className="flex gap-2" />}
              {preTitle && (
                <p className="text-h4 uppercase text-white">{preTitle}</p>
              )}
              {title && (
                <h2 className="max-w-prose text-d4 uppercase md:text-d8">
                  {title}
                </h2>
              )}
              {description && (
                <PortableText value={description} className="portable-text" />
              )}
              {actions && (
                <div className="flex flex-col gap-3 xs:flex-row">
                  {actions.map((action) => (
                    <Button
                      key={action.link?._key}
                      to={action.link?.to}
                      type={action.link?._type}
                      variant={action.variant}
                      width="auto"
                    >
                      {action?.link?.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
