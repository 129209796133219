import {SerializeFrom} from '@shopify/remix-oxygen';
import {SanityImage} from '~/components';
import {SanityCarBrand} from '~/types';

export function HeroLogo({
  carBrandContent,
}: {
  carBrandContent?: SanityCarBrand;
}) {
  const title = carBrandContent?.title;
  const logo = carBrandContent?.logo;
  return (
    <article className="flex flex-row items-center gap-4">
      <div className="w-35">
        {logo && (
          <SanityImage
            className="fadeIn backface-hidden transition-transform duration-500 "
            width={logo.width}
            height={logo.height}
            objectFit="cover"
            alt={title}
            sizes={[]}
            layout="responsive"
            src={logo?.asset?._ref}
          />
        )}
      </div>
    </article>
  );
}
