import type {PortableTextBlock} from '@portabletext/types';
import type {SanityColorTheme} from '~/types';

type Props = PortableTextBlock & {
  value: PortableTextBlock & {
    text: string;
    subtitle: string;
    theme: SanityColorTheme;
  };
};

export default function BlockCallout(props: Props) {
  const {
    value: {text, subtitle, theme},
  } = props;

  return (
    <div
      className="not-prose relative flex w-full flex-col py-15 lg:py-32"
      style={{
        background: theme?.background?.hex,
        color: theme?.text?.hex,
      }}
    >
      <div className="section-px mx-auto flex max-w-site flex-col items-center gap-5 lg:gap-8 ">
        {text && (
          <p className="max-w-prose-narrow text-center text-p5 lg:text-p8">
            {text}
          </p>
        )}

        {subtitle && (
          <p
            className="text-center text-p3 text-davys lg:text-p4"
            style={{
              color: `rgb(${theme?.text?.rgb.r}, ${theme?.text?.rgb.b}, ${theme?.text?.rgb.g}, .5)`,
            }}
          >
            {subtitle}
          </p>
        )}
      </div>
    </div>
  );
}
