import type {SerializeFrom} from '@shopify/remix-oxygen';
import {Carousel, ProductCard} from '~/components';
import {SanityShopifyProducts} from '~/types';
import {getRelevantShopifyProducts} from '~/lib/utils';
import {SanitySectionProducts} from '~/types';

export function Products({
  section,
  shopifyProducts,
}: {
  section: SerializeFrom<SanitySectionProducts>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
}) {
  const {title, description, products: sanityProducts} = section;

  const products = getRelevantShopifyProducts(sanityProducts, shopifyProducts);

  if (!products?.[0]?.id) return null;

  return (
    <section className="relative my-15 flex w-full flex-col overflow-hidden md:my-20">
      <div className="section-px">
        {products?.length && (
          <Carousel
            items={products}
            options={{
              slidesPerView: 1,
              breakpoints: {
                768: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: description ? 3 : 4,
                  spaceBetween: 0,
                },
              },
            }}
            title={title}
            description={description}
            as={ProductCard}
            prop="product"
          />
        )}
      </div>
    </section>
  );
}
