import type {SerializeFrom} from '@shopify/remix-oxygen';
import {SanityCarBrand, SanitySection} from '~/types';
import {
  HeroImage,
  Collections,
  Callout,
  Banner,
  Journal,
  Products,
  MediaText,
  Gallery,
  Snapshot,
  TextAccordions,
  SharedSections,
  Incentives,
  ProductFeatures,
  ProductGroup,
  PortableTextBlock,
  PageHeading,
  OrderIntro,
  OrderFeatures,
  CarBrands,
  CustomHeroImage,
  CustomMediaText,
} from '~/components';
import {SanityShopifyProducts} from '~/types';

type Props = {
  section: SerializeFrom<SanitySection>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
  carBrandContent?: SerializeFrom<SanityCarBrand>;
};

export function Sections({
  sections,
  shopifyProducts,
  carBrandContent,
}: {
  sections?: SerializeFrom<SanitySection[]>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
  carBrandContent?: SerializeFrom<SanityCarBrand>;
}) {
  return (
    <div className="w-full">
      {sections?.map((section) => (
        <Section
          key={section._key}
          section={section}
          shopifyProducts={shopifyProducts}
          carBrandContent={carBrandContent}
        />
      ))}
    </div>
  );
}

function Section({section, shopifyProducts, carBrandContent}: Props) {
  switch (section._type) {
    case 'section.heroImage':
      return <HeroImage section={section} />;
    case 'section.customHeroImage':
      return (
        <CustomHeroImage section={section} carBrandContent={carBrandContent} />
      );
    case 'section.customMediaText':
      return <CustomMediaText section={section} />;
    case 'section.collections':
      return <Collections section={section} />;
    case 'section.callout':
      return <Callout section={section} />;
    case 'section.banner':
      return <Banner section={section} />;
    case 'section.journal':
      return <Journal section={section} />;
    case 'section.carBrands':
      return <CarBrands section={section} />;
    case 'section.products':
      return <Products section={section} shopifyProducts={shopifyProducts} />;
    case 'section.mediaText':
      return <MediaText section={section} />;
    case 'section.gallery':
      return <Gallery section={section} />;
    case 'section.snapshot':
      return <Snapshot section={section} shopifyProducts={shopifyProducts} />;
    case 'section.incentives':
      return <Incentives section={section} />;
    case 'section.textAccordions':
      return <TextAccordions section={section} />;
    case 'section.portableTextBlock':
      return <PortableTextBlock section={section} />;
    case 'section.productFeatures':
      return <ProductFeatures section={section} />;
    case 'section.pageHeading':
      return <PageHeading section={section} />;
    case 'section.productGroup':
      return (
        <ProductGroup section={section} shopifyProducts={shopifyProducts} />
      );
    case 'section.orderIntro':
      return <OrderIntro section={section} />;
    case 'section.orderFeatures':
      return <OrderFeatures section={section} />;
    case 'section.sections':
      return <SharedSections section={section} />;
    default:
      return null;
  }
}
