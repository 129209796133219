export function Bmas() {
  const url = 'https://bmas.agency';
  const title = 'bmas';

  return (
    <div className="flex items-center justify-center gap-3 before:content-['ꞏ']">
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="hover:text-bmas"
      >
        web@{title}
      </a>
    </div>
  );
}
