import {PortableText} from '~/components';
import {SanitySectionPortableTextBlock} from '~/types';
import type {SerializeFrom} from '@shopify/remix-oxygen';

export function PortableTextBlock({
  section,
}: {
  section: SerializeFrom<SanitySectionPortableTextBlock>;
}) {
  const {body, date} = section;

  const formattedDate = new Date(date).toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <section className="my-20 px-6 sm:px-8 md:px-10">
      <div className="mx-auto max-w-site">
        <div className="grid gap-4 lg:grid-cols-2">
          <div className="text-p4">
            <p>Updated: {formattedDate}</p>
          </div>
          {body && (
            <PortableText value={body} className="portable-text max-w-4xl" />
          )}
        </div>
      </div>
    </section>
  );
}
