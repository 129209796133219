import clsx from 'clsx';
import {forwardRef, HTMLProps} from 'react';

export type InputProps = HTMLProps<HTMLInputElement> & {
  variant?: 'search' | 'minisearch' | 'default';
  className?: string;
  type?: string;
  [key: string]: any;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({className = '', type, variant = 'default', ...props}, ref) => {
    const variants = {
      search:
        'bg-transparent px-0 py-2 text-d4 w-full focus:ring-0 border-x-0 border-t-0 transition border-b-2 border-primary/10 focus:border-primary/90',
      minisearch:
        'bg-transparent hidden md:inline-block text-left border-b transition border-transparent -mb-px border-x-0 border-t-0 appearance-none px-0 py-1 focus:ring-transparent placeholder:opacity-40 placeholder:text-inherit',
      default:
        'bg-jet border transition border-transparent appearance-none p-3 px-6 focus:ring-transparent focus:border-spanishGray placeholder:text-spanishGray text-p4 rounded-full',
    };

    const styles = clsx(variants[variant], className);

    return <input type={type} {...props} className={styles} ref={ref} />;
  },
);
