import {Accordion, PortableText} from '~/components';
import {SanitySectionTextAccordions} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';

export function TextAccordions({
  section,
}: {
  section: SerializeFrom<SanitySectionTextAccordions>;
}) {
  const {intro, accordions, variant} = section;

  return (
    <section className="section-px my-15 lg:my-20">
      <div className="mx-auto max-w-site">
        <div className="grid grid-cols-6 gap-4">
          <div
            className={clsx('col-span-6 md:col-span-3 md:pr-12 ', {
              'lg:col-span-4': variant === '3col',
            })}
          >
            {intro && (
              <PortableText
                value={intro}
                className="portable-text max-w-4xl text-p5 lg:text-p7"
              />
            )}
          </div>
          <div
            className={clsx('col-span-6 mt-6 md:col-span-3 lg:mt-0', {
              'lg:col-span-2': variant === '3col',
            })}
          >
            {accordions?.length &&
              accordions.map((accordion) => (
                <Accordion key={accordion._key} title={accordion.title}>
                  <PortableText
                    value={accordion.body}
                    className="portable-text prose-sm text-p4"
                  />
                </Accordion>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
