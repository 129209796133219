import {
  faDiscord,
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedinIn,
  faMedium,
  faPinterest,
  faSnapchat,
  faTiktok,
  faTwitch,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ExternalLink, Heading} from '~/components';
import {SanitySocialLink} from '~/types';
import clsx from 'clsx';

type iconsProps = {
  [key: string]: {
    icon: any;
    color: string;
  };
};

const socialIcons: iconsProps = {
  facebook: {
    icon: faFacebook,
    color: 'hover:text-[#1877f2]',
  },
  twitter: {
    icon: faTwitter,
    color: 'hover:text-[#1da1f2]',
  },
  instagram: {
    icon: faInstagram,
    color: 'hover:text-[#c32aa3]',
  },
  youtube: {
    icon: faYoutube,
    color: 'hover:text-[#ff0000]',
  },
  linkedin: {
    icon: faLinkedinIn,
    color: 'hover:text-[#0a66c2]',
  },
  github: {
    icon: faGithub,
    color: 'hover:text-[#6cc644]',
  },
  medium: {
    icon: faMedium,
    color: 'hover:text-[#02b875]',
  },
  pinterest: {
    icon: faPinterest,
    color: 'hover:text-[#bd081c]',
  },
  twitch: {
    icon: faTwitch,
    color: 'hover:text-[#9146ff]',
  },
  discord: {
    icon: faDiscord,
    color: 'hover:text-[#5865f2]',
  },
  tiktok: {
    icon: faTiktok,
    color: 'hover:text-[#00f2ea]',
  },
  snapchat: {
    icon: faSnapchat,
    color: 'hover:text-[#fffc00]',
  },
};

export function SocialIcons({
  platforms,
  className = '',
  heading,
}: {
  platforms?: SanitySocialLink[];
  className?: string;
  heading?: string;
}) {
  if (!Array.isArray(platforms)) return null;

  return (
    <div className={clsx(className, 'flex flex-col gap-5 md:gap-7')}>
      {heading && (
        <Heading as="h3" size="copy">
          Follow Us
        </Heading>
      )}
      <ul className="flex flex-wrap gap-4 text-[20px]">
        {platforms?.map(({_key, url, platform}) => (
          <li key={_key}>
            <ExternalLink
              to={url}
              aria-label={platform}
              className={`${socialIcons[platform]?.color}`}
            >
              <FontAwesomeIcon icon={socialIcons[platform]?.icon} />
            </ExternalLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
