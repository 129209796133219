import {useRef, useMemo, useState} from 'react';
import {
  Button,
  HeroCaption,
  SanityImage,
  SanityMuxVideo,
  Logo,
  Brandmark,
} from '~/components';
import {SanitySectionHeroImage} from '~/types';

import {useLocation} from '@remix-run/react';
import {getHeaderTheme} from '~/lib/utils';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';
import {faCirclePlay} from '@fortawesome/sharp-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export function HeroImage({
  section,
}: {
  section: SerializeFrom<SanitySectionHeroImage>;
}) {
  const location = useLocation();
  const pathname = location.pathname ?? '/';
  const headerTheme = getHeaderTheme(pathname);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const {
    title,
    pretitle,
    caption,
    image,
    video,
    button,
    display,
    variant,
    scrollDown,
    overlay,
  } = section;

  const media = useMemo(() => {
    switch (display) {
      case 'image':
        return (
          <SanityImage
            crop={image?.crop}
            hotspot={image?.hotspot}
            width={1920}
            height={1080}
            objectFit="cover"
            layout="fill"
            sizes={[1000, 2000]}
            src={image?.asset?._ref}
          />
        );

      case 'video': {
        return (
          <SanityMuxVideo
            video={video}
            isPlaying={isPlaying}
            onPlay={handlePlay}
            onPause={handlePause}
            setMediaActive={setIsPlaying}
          />
        );
      }
    }
  }, [image, video, display, isPlaying]);

  const layout = useMemo(() => {
    switch (variant) {
      case 'default':
        return (
          <div
            className={clsx(
              'section-px z-10 h-full bg-gradient-to-r from-black/80 to-transparent  py-10 pt-nav transition-opacity duration-500',
              isPlaying && 'opacity-0',
            )}
          >
            {isPlaying && (
              <button className="absolute inset-0 z-10" onClick={handlePause} />
            )}
            <div className="mx-auto flex h-full max-w-site flex-col justify-end space-y-8 sm:justify-center">
              <div className="flex flex-col-reverse gap-6 md:grid md:grid-cols-2 lg:grid-cols-[1fr_108px_1fr] lg:gap-16">
                <div className="flex min-w-min flex-col gap-6 sm:max-w-[54ch] sm:items-start sm:pb-8">
                  {pretitle && (
                    <HeroCaption
                      as="p"
                      className="text-h4 uppercase text-white"
                    >
                      {pretitle}
                    </HeroCaption>
                  )}
                  {title && (
                    <h1 className="text-d5 uppercase text-white md:text-d6 lg:text-d7">
                      {title}
                    </h1>
                  )}
                  {caption && (
                    <p className="portable-text max-w-[80%]">{caption}</p>
                  )}
                  {button?.title && (
                    <Button
                      to={button.link?.to}
                      type={button.link?._type}
                      variant={button.variant}
                      width="auto"
                    >
                      {button?.link?.title}
                    </Button>
                  )}
                </div>
                {display === 'video' && (
                  <button
                    className="transform self-start text-p1 duration-150 hover:scale-110 md:self-center md:text-p5"
                    onClick={() => setIsPlaying(true)}
                  >
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      inverse
                      className="text-white"
                      size={'6x'}
                    />
                  </button>
                )}
              </div>
              {scrollDown && (
                <div className="flex items-end justify-between gap-4">
                  {/* scroll down */}
                  <button
                    className="underline-hover text-p2 text-white sm:text-p3"
                    onClick={() =>
                      nexSection.current?.scrollIntoView({behavior: 'smooth'})
                    }
                  >
                    Scroll down
                  </button>
                </div>
              )}
            </div>
          </div>
        );

      case 'center': {
        return (
          <div className="section-px relative z-10 flex h-screen flex-col items-center justify-between py-10">
            {title && (
              <h2 className="pointer-events-none invisible select-none">
                {title}
              </h2>
            )}
            <div className="h-100 mx-auto w-full max-w-site">
              {overlay && (
                <div className="m-auto flex w-full flex-col items-center text-white">
                  {(() => {
                    switch (overlay) {
                      case 'logo':
                        return (
                          <Logo className="h-auto w-full max-w-prose-wide" />
                        );
                      case 'brandmark':
                        return (
                          <Brandmark className="h-auto w-full max-w-[16rem]" />
                        );
                    }
                  })()}
                </div>
              )}
            </div>
            <div className="flex justify-center">
              {/* scroll down */}
              <button
                className="underline-hover text-p2 text-white sm:text-p3"
                onClick={() =>
                  nexSection.current?.scrollIntoView({behavior: 'smooth'})
                }
              >
                Scroll down
              </button>
            </div>
          </div>
        );
      }
    }
  }, [
    variant,
    isPlaying,
    pretitle,
    title,
    caption,
    button?.title,
    button.link?.to,
    button.link?._type,
    button.link?.title,
    button.variant,
    display,
    scrollDown,
    overlay,
  ]);

  const nexSection = useRef<HTMLDivElement>(null);

  return (
    <section
      className={clsx(
        'relative flex min-h-screen-nav-padding w-full flex-col justify-center',
        headerTheme !== 'transparent' ? 'h-screen-nav' : 'h-screen',
      )}
    >
      <div className="absolute inset-0 overflow-clip">{media}</div>
      {layout}
      <div ref={nexSection} />
    </section>
  );
}
