import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {useCallback, useEffect, useState} from 'react';
import {Clubhouse, Geo} from '~/types';
import {mapStyles} from './map.styles';
// import mapMarker from './mapMarker.svg';

const CENTER = {lng: -0.1810728, lat: 51.4824932};

export const Map = ({
  locations,
  selectedLocation,
  toggleLocation,
}: {
  locations: Clubhouse[];
  selectedLocation?: Clubhouse;
  toggleLocation: (value: Clubhouse) => void;
}) => {
  const centerLocation = locations[0]?.geo ?? CENTER;

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCxOwGupzNX4Ad3X5kZHbcx3QK6_zyXdu4',
  });

  const [map, setMap] = useState(null);

  const [panQueue, setPanQueue] = useState<Geo>();

  const onLoad = useCallback(
    function callback(map: any) {
      map.setOptions({
        center: centerLocation,
        zoom: 3,
      });
      setMap(map);
    },
    [centerLocation],
  );

  const onIdle = useCallback(
    function callback() {
      if (panQueue) {
        map.panTo(panQueue);
        map.setZoom(5);
        setPanQueue(undefined);
      }
    },
    [map, panQueue],
  );

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (!map) return;
    if (!selectedLocation) {
      map.setOptions({
        zoom: 3,
      });
    } else {
      smoothlyAnimatePanTo(selectedLocation.geo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const smoothlyAnimatePanTo = (geo: Geo) => {
    if (!map) return;
    map.setZoom(3);
    setPanQueue(geo);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName="h-full w-full"
      onLoad={onLoad}
      onIdle={onIdle}
      onUnmount={onUnmount}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        minZoom: 3,
        maxZoom: 10,
        restriction: {
          latLngBounds: {
            north: 85.0,
            south: -85.0,
            west: -180.0,
            east: 180.0,
          },
          strictBounds: true,
        },
      }}
    >
      {locations.map((location) => {
        return (
          <Marker
            icon={{
              // url: mapMarker,
              path: google.maps.SymbolPath.CIRCLE,
              strokeColor: 'white',
              scale: 7,
            }}
            title={location.name}
            key={location._id}
            position={location.geo}
            onClick={() => {
              toggleLocation(location);
            }}
          />
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
};
