import {Form, useLocation} from '@remix-run/react';
import clsx from 'clsx';
import {IconBag, IconAccount, Link, Button} from '~/components';
import type {ReactElement} from 'react';

import {IconProps} from '../elements/Icon';
export const accountMenuItems: {
  label: string;
  link: string;
  Icon: (props: IconProps) => ReactElement;
}[] = [
  {
    label: 'Overview',
    link: '/account',
    Icon: IconAccount,
  },
  {
    label: 'My Watches',
    link: '/account/watches',
    Icon: IconBag,
  },
  {
    label: 'My Straps',
    link: '/account/straps',
    Icon: IconBag,
  },
  {
    label: 'Order History',
    link: '/account/orders',
    Icon: IconBag,
  },
  {
    label: 'Address Book',
    link: '/account/address',
    Icon: IconAccount,
  },
  {
    label: 'Profile',
    link: '/account/profile',
    Icon: IconAccount,
  },
];

export function AccountMenu() {
  const {pathname} = useLocation();
  return (
    <aside className="flex w-full flex-col">
      <nav className="hidden md:flex">
        <ul className="flex flex-col gap-2">
          {accountMenuItems?.map((item) => {
            const isActive =
              item.link === '/account'
                ? pathname === item.link
                : pathname.startsWith(item.link);
            return (
              <li key={item.link}>
                <Link
                  to={item.link}
                  className={clsx('text-h5 transition-colors lg:text-h7', {
                    'text-white ': isActive,
                    'text-white/30 hover:text-white/60': !isActive,
                  })}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
}
