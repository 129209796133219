import {Heading, SanityImage} from '~/components';
import {SanitySectionIncentives, Incentive} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';

export function Incentives({
  section,
}: {
  section: SerializeFrom<SanitySectionIncentives>;
}) {
  const {items} = section;

  return (
    <section className="section-px border-0 border-b border-t">
      <div className="mx-auto flex max-w-site flex-col md:flex-row ">
        {items?.map((item) => (
          <IncentiveItem key={item._key} item={item} />
        ))}
      </div>
    </section>
  );
}

function IncentiveItem({item}: {item: Incentive}) {
  return (
    <div
      className={clsx(
        'flex flex-1 flex-col items-start justify-start',
        'border-b  last:border-0 md:border-0 md:border-r',
        'py-8 first:pl-0 md:py-20 md:px-5 lg:px-10',
      )}
    >
      <div className="flex max-w-prose-narrow flex-row items-start gap-7 md:flex-col">
        {item?.icon && (
          <SanityImage
            className="fadeIn mb-3 w-full max-w-[3.25rem] md:max-w-[4.125rem]"
            width={75}
            objectFit="contain "
            sizes={[75]}
            src={item?.icon?.asset?._ref}
          />
        )}
        <div>
          {item?.title && (
            <h3 className="mb-2 max-w-prose text-h4 lg:text-h6">
              {item.title}
            </h3>
          )}
          {item?.text && <p className="text-p3 lg:text-p4">{item.text}</p>}
        </div>
      </div>
    </div>
  );
}
