import {Heading} from '../elements';

export function CollectionHeader({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="mb-24 flex flex-col justify-center space-y-10 md:items-center md:text-center">
      <Heading as="h1" size="displayLg" className="uppercase">
        {title}
      </Heading>
      {description && (
        <p className="text-sm md:text-base max-w-prose">{description}</p>
      )}
    </div>
  );
}
