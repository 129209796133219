import {TrackingEnv} from '~/types';
import {GoogleAnalytics, KlaviyoTrack, MetaPixel} from '~/components';

export function AnalyticsScripts({env}: {env: TrackingEnv}) {
  if (process.env.NODE_ENV === 'development') return null;

  return (
    <>
      <GoogleAnalytics gtagId={env.gtagId} />
      <MetaPixel pixelId={env.pixelId} />
      <KlaviyoTrack klaviyoCompanyid={env.klaviyoCompanyId} />
    </>
  );
}
