import {Link, ProductTile} from '~/components';
import Tippy from '@tippyjs/react/headless';
import clsx from 'clsx';
import {SanityAssetImage, SanityShopifyProducts} from '~/types';
import {getVariantUrl} from '~/lib/utils';

type Props = {
  x: number;
  y: number;
  showHotspots: boolean;
  shopifyProduct: SanityShopifyProducts;
  varaintGid?: string;
  sanityImage?: SanityAssetImage;
};

export function ProductHotspot({
  shopifyProduct,
  varaintGid,
  x,
  y,
  showHotspots,
  sanityImage,
}: Props) {
  if (!shopifyProduct) {
    return null;
  }

  // if variantGid is set, find the variant and use that from now on
  const variant = shopifyProduct.variants?.nodes.find(
    (variant) => variant.id === varaintGid,
  );

  const url = getVariantUrl(shopifyProduct.handle, variant);

  const placementX = x < 25 || x > 75 ? (x < 50 ? '-start' : '-end') : '';
  const placementY = y > 50 ? 'top' : 'bottom';
  const placement = `${placementY}${placementX}`;

  return (
    <>
      <Tippy
        // @ts-expect-error TODO: fix this BEN!
        placement={placement}
        duration={1000}
        render={() => {
          return (
            <ProductTile
              product={shopifyProduct}
              varaintProduct={variant}
              sanityImage={sanityImage}
            />
          );
        }}
      >
        <div
          className={clsx(
            'absolute left-[50%] top-[50%] z-10 flex h-[15px] w-[15px] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full transition-opacity duration-300 ease-out',
            showHotspots ? 'opacity-100' : 'pointer-events-none opacity-0',
          )}
          style={{
            left: `${x}%`,
            top: `${y}%`,
          }}
        >
          <Link to={url}>
            <div className="relative h-[15px] w-[15px] animate-pulse rounded-full bg-white duration-300 ease-out hover:scale-50 hover:animate-none" />
          </Link>
        </div>
      </Tippy>
    </>
  );
}
