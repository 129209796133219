import {useEffect} from 'react';

import {Fancybox as NativeFancybox} from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';

export function Fancybox(props) {
  const delegate = props.delegate || '[data-fancybox]';

  useEffect(() => {
    const opts = props.options || {};

    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, []);

  return <>{props.children}</>;
}
