import {Heading, Link, SanityImage} from '~/components';
import {SanityJournal} from '~/types';

export function JournalCard({journal}: {journal: SanityJournal}) {
  const {slug, title, featuredImage} = journal;

  return (
    <article className="">
      <Link
        to={`/journal/${slug}`}
        className="group relative grid gap-8 overflow-hidden"
      >
        <div className="card-image-dark group aspect-[11/10] group-even:aspect-[4/5]">
          {featuredImage && (
            <SanityImage
              className="fadeIn backface-hidden transition-transform duration-500 group-hover:scale-105"
              crop={featuredImage?.crop}
              hotspot={featuredImage?.hotspot}
              width={440}
              height={550}
              objectFit="cover"
              layout="fill"
              sizes={[880, 1320]}
              src={featuredImage?.asset?._ref}
            />
          )}
        </div>
        <header className="flex flex-col items-start gap-4 text-white group-even:absolute group-even:bottom-0 group-even:left-0 group-even:z-10 group-even:p-8">
          <h2 className="max-w-prose text-h5 font-medium md:text-h6 md:font-semibold">
            {title}
          </h2>
          <p className="underline-hover text-p3 leading-none">Read more</p>
        </header>
      </Link>
    </article>
  );
}
