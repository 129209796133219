import {MoneyV2} from '@shopify/hydrogen/storefront-api-types';
import clsx from 'clsx';
import {isDiscounted, isNewArrival} from '~/lib/utils';

export function ProductLabels({
  publishedAt,
  price,
  compareAtPrice,
  className,
}: {
  publishedAt: string;
  price: MoneyV2;
  compareAtPrice: MoneyV2;
  className?: string;
}) {
  const discountPercentage = isDiscounted(price, compareAtPrice);

  const labels = [];
  if (isNewArrival(publishedAt)) {
    labels.push(<Label key="new" label="New" className="bg-dark text-white" />);
  }
  if (discountPercentage) {
    labels.push(
      <Label
        key="discount"
        label={`-${discountPercentage}%`}
        className="bg-olive text-silk"
      />,
    );
  }

  if (labels.length === 0) return null;

  return <div className={className}>{labels}</div>;
}

function Label({label, className}: {label: string; className?: string}) {
  return (
    <label
      className={clsx(
        'rounded-sm p-1.5 pb-1 text-right text-p2 leading-none tracking-widest',
        className,
      )}
    >
      {label}
    </label>
  );
}
