import {Await, useMatches} from '@remix-run/react';
import clsx from 'clsx';
import {Suspense, useEffect, useMemo, useState} from 'react';
import {useWindowScroll} from 'react-use';
import {
  Cart,
  CartLoading,
  Drawer,
  Heading,
  IconAccount,
  IconBag,
  IconClose,
  IconLogin,
  IconMenu,
  Link,
  Logo,
  useDrawer,
} from '~/components';
import {useCartFetchers} from '~/hooks/useCartFetchers';
import {useIsHydrated} from '~/hooks/useIsHydrated';
import {useIsHomePath} from '~/lib/utils';
import {
  HeaderTheme,
  SanityLink,
  SanityMenu,
  SanityMenus,
  SanitySiteBanner,
} from '~/types';

export function Header({
  menus,
  theme,
  siteBanner,
}: {
  menus?: SanityMenus;
  theme: HeaderTheme;
  siteBanner: SanitySiteBanner;
}) {
  const isHome = useIsHomePath();

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);

  return (
    <>
      <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
      {menus && (
        <MenuDrawer
          isOpen={isMenuOpen}
          onClose={closeMenu}
          mainMenu={menus?.main}
        />
      )}
      <HeaderBar
        isHome={isHome}
        menu={menus?.quick}
        openCart={openCart}
        openMenu={openMenu}
        theme={theme}
        siteBanner={siteBanner}
      />
    </>
  );
}

function CartDrawer({isOpen, onClose}: {isOpen: boolean; onClose: () => void}) {
  const [root] = useMatches();

  return (
    <Drawer open={isOpen} onClose={onClose} heading="Your bag" openFrom="right">
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}
export function MenuDrawer({
  isOpen,
  onClose,
  mainMenu,
}: {
  isOpen: boolean;
  onClose: () => void;
  mainMenu?: SanityMenu;
}) {
  const year = new Date().getFullYear();

  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left">
      <div className="grid h-screen-no-nav grid-cols-1 grid-rows-[1fr_auto] overflow-auto">
        <div className="flex flex-col p-6 sm:px-12">
          <h3 className="py-4 text-h6 uppercase text-white/60">
            Made in Britain
          </h3>
          <Link
            className="w-full border-t border-white py-4 text-h4 uppercase"
            onClick={onClose}
            to="?modal=enquire"
          >
            Make an enquiry
          </Link>
          <div className="grid border-t border-white py-4">
            <MainMenuNav menu={mainMenu} onClose={onClose} />
          </div>
          <div className="mt-auto">
            <div className="mt-auto grid grid-cols-2 gap-2 text-p3 text-dark">
              <Link onClick={onClose} to="/account">
                <span className="underline-hover inline-flex pb-1">
                  Account
                </span>
              </Link>
              <Link onClick={onClose} to="/contact">
                <span className="underline-hover inline-flex pb-1">
                  Contact
                </span>
              </Link>
            </div>
            <div className="mt-5 border-t pt-5 text-p3 text-dark/50">
              <span>&copy; {year} Senturion Key</span>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

function MainMenuNav({
  menu,
  onClose,
}: {
  menu?: SanityMenu;
  onClose: () => void;
}) {
  return (
    <nav className="grid gap-4 sm:gap-6">
      {menu?.links?.map((item) => (
        <span
          key={item._key}
          className="block text-h4 uppercase hover:opacity-100"
        >
          <Link
            to={item.to}
            target={item?.target}
            onClick={onClose}
            className={({isActive}) =>
              clsx('dot-hover', {
                'after:bg-dark/80 after:opacity-100': isActive,
              })
            }
          >
            <span className="block">{item.title}</span>
          </Link>
        </span>
      ))}
    </nav>
  );
}

function QuickMenuNav({menu, theme}: {menu?: SanityMenu; theme: HeaderTheme}) {
  return (
    <nav className="hidden h-full gap-6 md:flex">
      {menu?.links?.map((item) => (
        <span key={item._key} className="">
          <Link
            to={item.to}
            target={item.target}
            type={item._type}
            className={({isActive}) =>
              clsx(
                'hover:roll-activate flex h-full items-center justify-center',
                {
                  'after:scale-x-100': isActive,
                },
              )
            }
          >
            <span
              className={clsx('roll roll-white block pt-0.5 text-h3 uppercase')}
            >
              {item.title}
            </span>
          </Link>
        </span>
      ))}
    </nav>
  );
}

function HeaderBar({
  menu,
  isHome,
  openCart,
  openMenu,
  theme,
  siteBanner,
}: {
  menu?: SanityMenu;
  isHome: boolean;
  openCart: () => void;
  openMenu: () => void;
  theme: HeaderTheme;
  siteBanner: SanitySiteBanner;
}) {
  const [headerTheme, setHeaderTheme] = useState(theme);
  const {y} = useWindowScroll();

  // Set header theme on scroll and on mount
  useEffect(() => {
    if (theme === 'transparent' && y > 200) {
      setHeaderTheme('dark');
    } else {
      setHeaderTheme(theme);
    }
  }, [theme, y]);

  const [showBanner, setShowBanner] = useState(siteBanner?.active);

  return (
    <header
      role="banner"
      className={clsx(
        'sticky top-0 z-40 flex h-nav  w-full flex-col  border-b leading-none',
        {
          'border-white/10 bg-dark text-white': headerTheme === 'dark',
          'border-b-2 border-transparent bg-transparent text-white':
            headerTheme === 'transparent',
        },
      )}
    >
      {showBanner && (
        <SiteBanner
          body={siteBanner.body}
          link={siteBanner.link}
          onClose={() => setShowBanner(false)}
        />
      )}
      <div className="section-px flex h-full items-center justify-between gap-4">
        <div className="flex h-full w-full items-center justify-start gap-6">
          <button
            onClick={openMenu}
            className="relative flex items-center justify-center"
          >
            <span
              className={clsx('shimmer', {
                'shimmer-dark': headerTheme === 'dark',
                'shimmer-transparent': headerTheme === 'transparent',
              })}
            >
              <IconMenu className="w-6" />
            </span>
          </button>
          <QuickMenuNav menu={menu} theme={headerTheme} />
        </div>

        <Link
          className="flex h-full w-full flex-grow items-center justify-center self-stretch leading-[3rem] md:leading-[4rem]"
          to="/"
        >
          <Heading className="text-center font-bold" as={isHome ? 'h1' : 'h2'}>
            <Logo className="w-32 md:w-auto" />
          </Heading>
        </Link>

        <div className="flex h-full w-full items-center justify-end gap-4 md:gap-6">
          <AccountLink className="relative flex h-8 w-8 items-center justify-center focus:ring-primary/5" />
          <CartCount headerTheme={headerTheme} openCart={openCart} />
        </div>
      </div>
    </header>
  );
}

function AccountLink({className}: {className?: string}) {
  const [root] = useMatches();
  const isLoggedIn = root.data?.isLoggedIn;
  return isLoggedIn ? (
    <Link to="/account" className={className}>
      <IconAccount />
    </Link>
  ) : (
    <Link to="/account/login" className={className}>
      <IconLogin />
    </Link>
  );
}

function SiteBanner({
  body,
  link,
  onClose,
}: {
  body: string;
  link: SanityLink;
  onClose: () => void;
}) {
  useEffect(() => {
    document
      .getElementById('varUpdate')
      ?.style.setProperty('--height-nav', 'var(--height-nav-with-banner)');
    return () => {
      document
        .getElementById('varUpdate')
        ?.style.removeProperty('--height-nav');
    };
  }, []);

  return (
    <div className="relative flex h-[2rem] items-center justify-center bg-olive py-2 text-center text-silk selection:bg-dark md:h-[2.5rem]">
      <p className="text-p1 md:text-p3">
        {body}
        {link?.title && (
          <>
            {' - '}
            <Link to={link?.to} type={link?._type}>
              <span className="underline hover:opacity-75">{link?.title}</span>
            </Link>
          </>
        )}
      </p>
      <button
        className="absolute right-0 top-0 flex h-full w-[2rem] items-center justify-center"
        onClick={onClose}
      >
        <IconClose className="w-4" />
      </button>
    </div>
  );
}

function CartCount({
  headerTheme,
  openCart,
}: {
  headerTheme: HeaderTheme;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <Suspense
      fallback={
        <CartBadge count={0} headerTheme={headerTheme} openCart={openCart} />
      }
    >
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <CartBadge
            headerTheme={headerTheme}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function CartBadge({
  openCart,
  headerTheme,
  count,
}: {
  count: number;
  headerTheme: HeaderTheme;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`absolute bottom-1 right-1 flex h-3 w-auto min-w-[0.75rem] items-center justify-center rounded-full bg-dark px-[0.125rem] pb-px text-center text-[0.625rem] font-medium leading-none text-silk subpixel-antialiased`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex h-8 w-8 items-center justify-center focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex h-8 w-8 items-center justify-center focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}
