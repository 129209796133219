import {Disclosure, Popover, Transition} from '@headlessui/react';
import {
  Link,
  Location,
  useLocation,
  useNavigate,
  useSearchParams,
} from '@remix-run/react';
import {Fragment, SyntheticEvent, useMemo, useRef, useState} from 'react';
import {useDebounce} from 'react-use';
import {
  Button,
  ColorOption,
  Drawer,
  IconCaret,
  IconXMark,
  useDrawer,
} from '~/components';

import type {
  Collection,
  Filter,
  FilterType,
} from '@shopify/hydrogen/storefront-api-types';
import clsx from 'clsx';
import {searchParamAddOrRemove} from '~/lib/utils';
import {AppliedFilter, SortParam} from '~/routes/collections/$collectionHandle';
import {SizeOption} from '../elements/SizeOption';

type Props = {
  filters: Filter[];
  appliedFilters?: AppliedFilter[];
  collections?: Collection[];
};

export function SortFilter({
  filters,
  appliedFilters = [],
  collections = [],
}: Props) {
  const {
    isOpen: isFiltersOpen,
    openDrawer: openFilters,
    closeDrawer: closeFilters,
  } = useDrawer();

  return (
    <>
      <div className="absolute right-0 -top-14 bottom-0 text-dark">
        <div className="sticky right-0 top-[calc(var(--height-nav)+32px)] z-30 inline-flex items-center gap-2 ">
          <SortMenu />
          <button
            onClick={openFilters}
            className={
              'relative flex items-center justify-center rounded-3xl bg-white px-2 pt-2.5 pb-2 text-p3 font-medium leading-none focus:ring-primary/5'
            }
          >
            Filter
            {appliedFilters?.length ? (
              <span className="ml-1 flex h-4 w-4 items-center justify-center rounded-full bg-dark text-p1 leading-none text-white">
                {appliedFilters?.length}
              </span>
            ) : null}
          </button>
        </div>
      </div>
      <FiltersDrawer
        isOpen={isFiltersOpen}
        onClose={closeFilters}
        collections={collections}
        filters={filters}
        appliedFilters={appliedFilters}
      />
    </>
  );
}

export function FiltersDrawer({
  isOpen,
  onClose,
  filters = [],
  collections = [],
}: {
  isOpen: boolean;
  onClose: () => void;
  filters: Filter[];
  appliedFilters: AppliedFilter[];
  collections: Collection[];
}) {
  const [params] = useSearchParams();
  const location = useLocation();

  // close on filter update
  // const transition = useTransition();
  // useEffect(() => {
  //   if (isOpen && transition.state !== 'idle') {
  //     onClose();
  //   }
  // }, [isOpen, transition.state, onClose]);

  // update filter values to only include accepted sizes
  const acceptedSizes = ['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'];
  const formattedFilters = filters.map((filter) => {
    if (filter.id === 'filter.v.option.size') {
      return {
        ...filter,
        values: filter.values.filter((value) =>
          acceptedSizes.includes(value.label),
        ),
      };
    }
    return filter;
  });

  const filterMarkup = (filter: Filter) => {
    switch (filter.id) {
      case 'filter.v.price':
        const min =
          params.has('minPrice') && !isNaN(Number(params.get('minPrice')))
            ? Number(params.get('minPrice'))
            : undefined;

        const max =
          params.has('maxPrice') && !isNaN(Number(params.get('maxPrice')))
            ? Number(params.get('maxPrice'))
            : undefined;

        return <PriceRangeFilter min={min} max={max} />;

      case 'filter.v.option.size':
        return (
          <SizeFilters filter={filter} params={params} location={location} />
        );

      case 'filter.v.option.color':
        return (
          <ColorFilters filter={filter} params={params} location={location} />
        );

      default:
        return (
          <DefaultFilters filter={filter} params={params} location={location} />
        );
    }
  };

  const collectionsMarkup = collections.map((collection) => {
    return (
      <li key={collection.handle} className="">
        <Link
          to={`/collections/${collection.handle}`}
          className="text-p3 font-medium hover:underline focus:underline"
          key={collection.handle}
          prefetch="intent"
        >
          {collection.title}
        </Link>
      </li>
    );
  });

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      openFrom="right"
      heading="Filter products"
    >
      <div className="grid h-screen-no-nav grid-cols-1 grid-rows-[1fr_auto] ">
        <section className="flex flex-col overflow-auto px-6 pb-6 md:px-12">
          <nav className="border-t pb-8">
            <div className="divide-y">
              {formattedFilters.map(
                (filter: Filter, index: number) =>
                  filter.values.length > 0 && (
                    <Disclosure
                      defaultOpen={index < 3}
                      as="div"
                      key={filter.id}
                      className="w-full"
                    >
                      {({open}) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between py-4">
                            <span className="text-h3">{filter.label}</span>
                            <IconCaret direction={open ? 'up' : 'down'} />
                          </Disclosure.Button>
                          <Disclosure.Panel key={filter.id}>
                            {filterMarkup(filter)}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ),
              )}
              <Disclosure as="div" key="filter_collections" className="w-full">
                {({open}) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between py-4">
                      <span className="text-h3">Collections</span>
                      <IconCaret direction={open ? 'up' : 'down'} />
                    </Disclosure.Button>
                    <Disclosure.Panel key="filter_collections">
                      <ul key="filter_collections" className="py-2">
                        {collectionsMarkup}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </nav>
        </section>
        <section className="px-6 pb-6 sm:px-12">
          <div className="flex justify-between gap-3 border-t pt-4">
            <Button to={location.pathname} variant="outline">
              Reset
            </Button>
            <Button onClick={onClose} variant="dark" width="full">
              Show Results
            </Button>
          </div>
        </section>
      </div>
    </Drawer>
  );
}

export function AppliedFilters({filters = []}: {filters: AppliedFilter[]}) {
  const [params] = useSearchParams();
  const location = useLocation();
  return (
    <>
      <div className="flex flex-wrap gap-2">
        {filters.map((filter: AppliedFilter) => {
          return (
            <Link
              to={getAppliedFilterLink(filter, params, location)}
              className="gap flex rounded-full border px-2"
              key={`${filter.label}-${filter.urlParam}`}
            >
              <span className="flex-grow">{filter.label}</span>
              <span>
                <IconXMark />
              </span>
            </Link>
          );
        })}
      </div>
    </>
  );
}

function getAppliedFilterLink(
  filter: AppliedFilter,
  params: URLSearchParams,
  location: Location,
) {
  const paramsClone = new URLSearchParams(params);
  if (filter.urlParam.key === 'variantOption') {
    const variantOptions = paramsClone.getAll('variantOption');
    const filteredVariantOptions = variantOptions.filter(
      (options) => !options.includes(filter.urlParam.value),
    );
    paramsClone.delete(filter.urlParam.key);
    for (const filteredVariantOption of filteredVariantOptions) {
      paramsClone.append(filter.urlParam.key, filteredVariantOption);
    }
  } else {
    paramsClone.delete(filter.urlParam.key);
  }
  return `${location.pathname}?${paramsClone.toString()}`;
}

function getSortLink(
  sort: SortParam,
  params: URLSearchParams,
  location: Location,
) {
  params.set('sort', sort);
  return `${location.pathname}?${params.toString()}`;
}

function getFilterLink(
  filter: Filter,
  rawInput: string | Record<string, any>,
  params: URLSearchParams,
  location: ReturnType<typeof useLocation>,
  isActive: boolean,
  // currLabel: string,
) {
  // if (isActive) {
  //   paramsClone.getAll('variantOption');
  //   //only delete the current size param variationOption=filter.label
  //   const variantOptions = paramsClone.getAll('variantOption');
  //   const filteredVariantOptions = variantOptions.filter(
  //     (options) => options.split(':')[1] !== currLabel,
  //   );
  //   paramsClone.delete('variantOption');
  //   for (const filteredVariantOption of filteredVariantOptions) {
  //     paramsClone.append('variantOption', filteredVariantOption);
  //   }
  //   return `${location.pathname}?${paramsClone.toString()}`;
  // }
  const paramsClone = new URLSearchParams(params);
  const newParams = filterInputToParams(
    filter.type,
    rawInput,
    paramsClone,
    !isActive,
  );
  return `${location.pathname}?${newParams.toString()}`;
}

const PRICE_RANGE_FILTER_DEBOUNCE = 500;

function PriceRangeFilter({max, min}: {max?: number; min?: number}) {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const navigate = useNavigate();

  const [minPrice, setMinPrice] = useState(min ? String(min) : '');
  const [maxPrice, setMaxPrice] = useState(max ? String(max) : '');

  useDebounce(
    () => {
      if (
        (minPrice === '' || minPrice === String(min)) &&
        (maxPrice === '' || maxPrice === String(max))
      )
        return;

      const price: {min?: string; max?: string} = {};
      if (minPrice !== '') price.min = minPrice;
      if (maxPrice !== '') price.max = maxPrice;

      const newParams = filterInputToParams('PRICE_RANGE', {price}, params);
      navigate(`${location.pathname}?${newParams.toString()}`);
    },
    PRICE_RANGE_FILTER_DEBOUNCE,
    [minPrice, maxPrice],
  );

  const onChangeMax = (event: SyntheticEvent) => {
    const newMaxPrice = (event.target as HTMLInputElement).value;
    setMaxPrice(newMaxPrice);
  };

  const onChangeMin = (event: SyntheticEvent) => {
    const newMinPrice = (event.target as HTMLInputElement).value;
    setMinPrice(newMinPrice);
  };

  return (
    <div className="flex flex-col py-2">
      <label className="mb-4">
        <span>from</span>
        <input
          name="maxPrice"
          className="text-black"
          type="text"
          defaultValue={min}
          placeholder={'$'}
          onChange={onChangeMin}
        />
      </label>
      <label>
        <span>to</span>
        <input
          name="minPrice"
          className="text-black"
          type="number"
          defaultValue={max}
          placeholder={'$'}
          onChange={onChangeMax}
        />
      </label>
    </div>
  );
}

function filterInputToParams(
  type: FilterType,
  rawInput: string | Record<string, any>,
  params: URLSearchParams,
  isAdding = true,
) {
  const input = typeof rawInput === 'string' ? JSON.parse(rawInput) : rawInput;
  switch (type) {
    case 'PRICE_RANGE':
      if (input.price.min) params.set('minPrice', input.price.min);
      if (input.price.max) params.set('maxPrice', input.price.max);
      break;
    case 'LIST':
      Object.entries(input).forEach(([key, value]) => {
        if (typeof value === 'string') {
          searchParamAddOrRemove(
            params,
            key,
            value,
            isAdding ? 'set' : 'remove',
          );
        } else if (typeof value === 'boolean') {
          searchParamAddOrRemove(
            params,
            key,
            value.toString(),
            isAdding ? 'set' : 'remove',
          );
        } else {
          const {name, value: val} = value as {name: string; value: string};
          const newVariant = `${name}:${val}`;
          searchParamAddOrRemove(
            params,
            'variantOption',
            newVariant,
            isAdding ? 'append' : 'remove',
          );
        }
      });
      break;
  }

  return params;
}

function SizeFilters({
  filter,
  params,
  location,
}: {
  filter: Filter;
  params: URLSearchParams;
  location: Location;
}) {
  //get active params for size
  const activeSizeParams = params.getAll('variantOption'); // format of variantOption: "size:XS"
  const activeSizes = activeSizeParams.map((param) => param.split(':')[1]);
  return (
    <ul
      key={filter.id}
      className="mb-4 inline-flex flex-wrap rounded-sm border border-black bg-white"
    >
      {filter.values?.map((option, i) => {
        const isActive = activeSizes.includes(option.label);
        const to = getFilterLink(
          filter,
          option.input as string,
          params,
          location,
          isActive,
        );

        return (
          <li key={option.id}>
            <Link prefetch="intent" to={to}>
              <SizeOption value={option.label} isActive={isActive} index={i} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

function ColorFilters({
  filter,
  params,
  location,
}: {
  filter: Filter;
  params: URLSearchParams;
  location: Location;
}) {
  //get active params for size
  const activeColorParams = params.getAll('variantOption'); // format of variantOption: "size:XS"
  const activeColors = activeColorParams.map((param) => param.split(':')[1]);
  return (
    <ul key={filter.id} className="mb-4 inline-flex flex-wrap gap-1">
      {filter.values?.map((option, i) => {
        const isActive = activeColors.includes(option.label);
        const to = getFilterLink(
          filter,
          option.input as string,
          params,
          location,
          isActive,
        );

        const colorValue = option.label.toLowerCase();

        return (
          <li key={option.id}>
            <Link prefetch="intent" to={to}>
              <ColorOption
                size="large"
                value={colorValue}
                isActive={isActive}
              />
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

function DefaultFilters({
  filter,
  params,
  location,
}: {
  filter: Filter;
  params: URLSearchParams;
  location: Location;
}) {
  //TODO: use the end part of filter id as param key so we can better track active state.
  const activeParams = Array.from(params.values());

  return (
    <ul key={filter.id} className="py-2">
      {filter.values?.map((option) => {
        const isAcitve = activeParams.includes(option.label);
        const to = getFilterLink(
          filter,
          option.input as string,
          params,
          location,
          isAcitve,
        );
        return (
          <li key={option.id} className="pb-4">
            <Link
              className="flex text-p3 font-medium"
              prefetch="intent"
              to={to}
            >
              <span
                className={clsx(
                  'mr-2 inline-block h-4 w-4 rounded-sm border border-black',
                  {
                    'bg-dark': isAcitve,
                  },
                )}
              ></span>
              {option.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

export default function SortMenu() {
  const items: {label: string; key: SortParam}[] = [
    {label: 'Featured', key: 'featured'},
    {
      label: 'Price: Low - High',
      key: 'price-low-high',
    },
    {
      label: 'Price: High - Low',
      key: 'price-high-low',
    },
    {
      label: 'Best Selling',
      key: 'best-selling',
    },
    {
      label: 'Newest',
      key: 'newest',
    },
  ];

  const location = useLocation();
  const locationSearch = location.search;
  const params = new URLSearchParams(locationSearch);
  const activeItem = items.find((item) => item.key === params.get('sort'));

  const buttonRef = useRef<HTMLButtonElement>(null);

  let timeout: ReturnType<typeof setTimeout>;

  const onMouseEnter = (open: boolean) => {
    clearTimeout(timeout);
    if (open) return;
    return buttonRef.current?.click();
  };

  const onMouseLeave = (open: boolean, close: () => void) => {
    if (!open) return;
    timeout = setTimeout(() => close(), 100);
  };

  return (
    <Popover as="div" className="relative">
      {({open, close}) => {
        return (
          <>
            {/* overlay */}
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 "
              enterTo="opacity-100 "
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 "
              leaveTo="opacity-0 "
            >
              <Popover.Overlay className="fixed inset-0 z-20 bg-black/20" />
            </Transition>
            <div>
              <Popover.Button
                ref={buttonRef}
                onMouseEnter={() => onMouseEnter(open)}
                onMouseLeave={() => onMouseLeave(open, close)}
                onFocus={() => onMouseEnter(open)}
                onBlur={() => onMouseLeave(open, close)}
                className="relative z-30 flex items-center rounded-3xl bg-white px-2 pt-2.5 pb-2 text-p3 font-medium leading-none outline-none"
              >
                Sort by
                {/* <span className="px-2">
                    <span className="px-2 font-medium">Sort by</span>
                    <span>{(activeItem || items[0]).label}</span>
                  </span>
                  <IconCaret /> */}
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  as="nav"
                  onMouseEnter={() => onMouseEnter(open)}
                  onMouseLeave={() => onMouseLeave(open, close)}
                  onFocus={() => onMouseEnter(open)}
                  onBlur={() => onMouseLeave(open, close)}
                  className="absolute right-0 z-30 pt-2"
                >
                  <div className="flex w-max flex-col rounded-md bg-white p-4 ">
                    {items.map((item) => (
                      <div key={item.label}>
                        <Link
                          className={`block px-3 pb-2 text-p3 ${
                            activeItem?.key === item.key
                              ? 'font-bold'
                              : 'font-normal'
                          }`}
                          to={getSortLink(item.key, params, location)}
                        >
                          {item.label}
                        </Link>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          </>
        );
      }}
    </Popover>
  );
}
