import {PortableText} from '~/components';
import {SanitySectionOrderIntro} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function OrderIntro({
  section,
}: {
  section: SerializeFrom<SanitySectionOrderIntro>;
}) {
  const {title, body, video} = section;

  return (
    <section>
      {title && <h2 className="text-d5 uppercase">{title}</h2>}
      {body && (
        <PortableText
          value={body}
          className="portable-text max-w-2xl text-p5 text-white "
        />
      )}
      {/* {video && <div>Video Link</div>} */}
    </section>
  );
}
