// prettier-ignore
import type { PortableTextBlock, PortableTextMarkDefinition} from '@portabletext/types';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/pro-regular-svg-icons';

type Props = PortableTextBlock & {
  value: PortableTextMarkDefinition & {
    email: string;
  };
};

const LinkEmailAnnotation = (props: Props) => {
  const {
    children,
    value: {email},
  } = props;

  return (
    <a
      className={clsx(
        'transition-opacity duration-200', //
        'hover:opacity-60',
      )}
      href={`mailto:${email}`}
    >
      <FontAwesomeIcon
        icon={faEnvelope}
        className="mr-[2px] !align-middle"
        size="sm"
      />
      <>{children}</>
    </a>
  );
};

export default LinkEmailAnnotation;
