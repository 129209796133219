import {
  JournalCard,
  Carousel,
  Newsletter,
  Brandmark,
  Heading,
} from '~/components';
import {SanitySectionJournal} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function Journal({
  section,
}: {
  section: SerializeFrom<SanitySectionJournal>;
}) {
  const {title, selected} = section;

  return (
    <section className="relative flex w-full flex-col overflow-hidden bg-dark text-silk">
      <div className="section-px py-15 pb-15 md:pt-28">
        <Carousel
          items={selected}
          title={title}
          as={JournalCard}
          prop="journal"
          className="group"
        />
        <div className="mx-auto mt-10 flex max-w-site justify-between border-t border-white/10 pt-10 md:mt-15 md:pt-15">
          <div className="flex flex-col gap-5 md:gap-7 lg:w-1/2">
            <Heading as="h3" size="copy">
              Join our newsletter
            </Heading>
            <Newsletter source="journal" />
          </div>
          <Brandmark className="hidden h-auto w-[5rem] max-w-full text-white/10 md:block" />
        </div>
      </div>
    </section>
  );
}
