import {useMemo, useState} from 'react';
import {Button, HeroCaption, SanityImage, SanityMuxVideo} from '~/components';
import {SanitySectionBanner} from '~/types';
import clsx from 'clsx';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCirclePlay} from '@fortawesome/sharp-solid-svg-icons';

export function Banner({
  section,
}: {
  section: SerializeFrom<SanitySectionBanner>;
}) {
  const {title, preTitle, description, image, video, button, display, variant} =
    section;

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const media = useMemo(() => {
    switch (display) {
      case 'image':
        return (
          <SanityImage
            crop={image?.crop}
            hotspot={image?.hotspot}
            width={1920}
            height={1132}
            objectFit="cover"
            layout="fill"
            sizes={[1000, 2000]}
            src={image?.asset?._ref}
          />
        );

      case 'video': {
        return (
          <SanityMuxVideo
            video={video}
            isPlaying={isPlaying}
            onPlay={handlePlay}
            onPause={handlePause}
            setMediaActive={setIsPlaying}
          />
        );
      }
    }
  }, [image, video, display, isPlaying]);

  return (
    <section
      className={clsx(
        'relative flex w-full flex-col',
        variant === 'left'
          ? 'h-[60vh] justify-center md:h-[75vh] md:justify-end'
          : 'aspect-[4/5] justify-center md:aspect-[21/9]',
      )}
    >
      <div className="absolute inset-0 overflow-clip">{media}</div>
      <div
        className={clsx(
          'section-px z-10 flex-1 py-10',
          variant === 'left' &&
            'flex h-full items-end bg-gradient-to-t from-black/80 to-black/30 md:h-auto md:items-center md:bg-gradient-to-r md:from-black/80 md:to-transparent',
          isPlaying && 'opacity-0',
        )}
      >
        {isPlaying && (
          <button className="absolute inset-0 z-10" onClick={handlePause} />
        )}
        <div className="flex w-full flex-col-reverse gap-6 md:grid md:grid-cols-2 lg:grid-cols-[1fr_108px_1fr] lg:gap-16">
          <div className="mx-auto flex w-full max-w-site gap-8 space-y-8 md:flex-col md:gap-0">
            <div
              className={clsx(
                variant === 'left' &&
                  'flex w-full min-w-min flex-col items-start gap-7 md:max-w-[50ch] md:pb-8',
                variant === 'center' &&
                  'mx-auto flex flex-col items-center justify-between gap-10',
              )}
            >
              <div className="flex flex-col gap-6">
                {preTitle && (
                  <HeroCaption className="text-h4 uppercase text-white">
                    {preTitle}
                  </HeroCaption>
                )}
                {title && (
                  <h2
                    className={clsx('text-white', {
                      'text-d5 uppercase text-white lg:text-d9':
                        variant === 'left',
                      'mx-auto flex max-w-site flex-col items-center justify-between text-center text-d3 uppercase lg:text-d7':
                        variant === 'center',
                    })}
                  >
                    {title}
                  </h2>
                )}
                {description && (
                  <p className="portable-text max-w-[80%]">{description}</p>
                )}
              </div>
              {button?.link && (
                <Button
                  to={button.link?.to}
                  type={button.link?._type}
                  variant={button.variant}
                  width="auto"
                >
                  {button?.link?.title}
                </Button>
              )}
            </div>
          </div>
          {display === 'video' && (
            <button
              className="transform self-start text-p1 duration-150 hover:scale-110 md:self-center md:text-p5"
              onClick={() => setIsPlaying(true)}
            >
              <FontAwesomeIcon
                icon={faCirclePlay}
                inverse
                className="text-white"
                size={'6x'}
              />
            </button>
          )}
        </div>
      </div>
    </section>
  );
}
