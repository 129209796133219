import {useSearchParams} from '@remix-run/react';
import {
  flattenConnection,
  Image,
  Money,
  ShopifyAnalyticsProduct,
  useMoney,
} from '@shopify/hydrogen';
import {
  Image as ImageType,
  MediaImage,
  MoneyV2,
  Product,
  ProductVariant,
} from '@shopify/hydrogen/storefront-api-types';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {AddToCartButton, ColorOption, Link, ProductLabels} from '~/components';
import {getProductPlaceholder} from '~/lib/placeholders';
import {formatMetafields, isDiscounted} from '~/lib/utils';

export function ProductCard({
  product,
  className,
  loading,
  onClick,
  quickAdd,
}: {
  product: SerializeFrom<Product>;
  className?: string;
  loading?: HTMLImageElement['loading'];
  onClick?: () => void;
  quickAdd?: boolean;
}) {
  const cardProduct: Product = product?.variants
    ? (product as Product)
    : getProductPlaceholder();

  const variants = flattenConnection(cardProduct.variants) || [];

  // if query params for color are present, we use that to select the variant
  const [params] = useSearchParams();
  const paramVariantOptions = params.getAll('variantOption');
  const colorParams = paramVariantOptions?.filter((option) =>
    option.startsWith('color'),
  );
  const firstVariant = variants[0];
  // const variantFromParam =
  //   colorParams?.[0] &&
  //   variants.find(
  //     (variant) =>
  //       variant.selectedOptions.find((option) => option.name === 'Color')
  //         ?.value === colorParams[0].split(':')[1],
  //   );

  const sortedVariants = variants.sort((a, b) => {
    const aStyle = parseInt(
      (
        a?.selectedOptions?.find?.((el) => el.name === 'Edition')?.value || ''
      ).match(/\d+/)?.[0] || '0',
      10,
    );
    const bStyle = parseInt(
      (
        b?.selectedOptions?.find?.((el) => el.name === 'Edition')?.value || ''
      ).match(/\d+/)?.[0] || '0',
      10,
    );

    return aStyle - bStyle;
  });

  const availableVariants = sortedVariants.filter(
    (variant) => variant.availableForSale,
  );

  // if in stock select the earliest edition otherwise select the last edition
  const initialvariant = availableVariants?.length
    ? availableVariants[0]
    : sortedVariants[sortedVariants.length - 1];

  // const initialvariant = variantFromParam ? variantFromParam : firstVariant;

  const variantMetafields = formatMetafields(initialvariant.metafields);

  const [selectedVariant, setSelectedVariant] =
    useState<ProductVariant>(initialvariant);

  // check if params have changed and update selected variant
  useEffect(() => {
    if (initialvariant.id !== selectedVariant.id) {
      setSelectedVariant(initialvariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialvariant]);

  if (!initialvariant) return null;

  const {price, compareAtPrice} = selectedVariant;

  const media = flattenConnection(cardProduct.media) as MediaImage[];
  const image = media?.[1]?.image;
  const hoverImage = media?.[2]?.image;

  const productAnalytics: ShopifyAnalyticsProduct = {
    productGid: product.id,
    variantGid: firstVariant.id,
    name: product.title,
    variantName: firstVariant.title,
    brand: product.vendor,
    price: firstVariant.price.amount,
    quantity: 1,
  };

  const productMetafields = formatMetafields(product.metafields);

  return (
    <div className="flex flex-col gap-2">
      <Link
        onClick={onClick}
        to={`/products/${product.handle}`}
        prefetch="intent"
      >
        <div className={clsx('grid gap-4', className)}>
          <div className="card-image group relative aspect-[5/6]">
            {image && (
              <Image
                className="fadeIn w-full"
                widths={[320]}
                sizes="320px"
                loaderOptions={{
                  crop: 'center',
                  scale: 2,
                  width: 320,
                  height: 400,
                }}
                data={image}
                alt={image.altText || `Picture of ${product.title}`}
                loading={loading}
              />
            )}
            {hoverImage && hoverImage.url !== image?.url ? (
              <div className="absolute inset-0 aspect-[5/6] w-full opacity-0 transition-opacity group-hover:opacity-100">
                <Image
                  className="fadeIn w-full"
                  widths={[320]}
                  sizes="320px"
                  loaderOptions={{
                    crop: 'center',
                    scale: 2,
                    width: 320,
                    height: 400,
                  }}
                  // @ts-ignore
                  data={hoverImage}
                  alt={`Picture of ${product.title}`}
                  loading="lazy"
                />
              </div>
            ) : null}
          </div>
          <div className="grid gap-1 text-center">
            <ProductLabels
              className="flex justify-center gap-2"
              publishedAt={product.publishedAt}
              price={price as MoneyV2}
              compareAtPrice={compareAtPrice as MoneyV2}
            />
            <h3 className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-h8">
              {product.title}
            </h3>
            {productMetafields.subtitle && (
              <span className="text-p3 uppercase">
                {productMetafields.subtitle}
              </span>
            )}
            <div className="flex justify-center gap-4">
              <span className="flex gap-2 text-p3">
                {isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2) && (
                  <CompareAtPrice
                    className={'text-web'}
                    data={compareAtPrice as MoneyV2}
                  />
                )}
                {variantMetafields?.editionRetailPrice && (
                  <Money
                    withoutTrailingZeros
                    data={variantMetafields?.editionRetailPrice}
                    as="span"
                    className="text-sonicSilver"
                  />
                )}
                {/* <Money withoutTrailingZeros data={price!} /> */}
              </span>
            </div>
          </div>
        </div>
      </Link>

      {quickAdd && (
        <AddToCartButton
          lines={[
            {
              quantity: 1,
              merchandiseId: firstVariant.id,
            },
          ]}
          variant="dark"
          className="mt-2"
          analytics={{
            products: [productAnalytics],
            totalValue: parseFloat(productAnalytics.price),
          }}
        >
          <span>Add to Bag</span>
        </AddToCartButton>
      )}
    </div>
  );
}

function ColorOptions({
  options,
  variants,
  selectedVariant,
  setSelectedVariant,
}: {
  options?: string[];
  variants: ProductVariant[];
  selectedVariant: ProductVariant;
  setSelectedVariant: (variant: ProductVariant) => void;
}) {
  if (!options || options.length < 2) return null;

  return (
    <div className="flex gap-1">
      {options.map((value) => {
        const variant = variants.find((variant) => {
          return variant.selectedOptions?.find(
            (option) => option.name === 'Color' && option.value === value,
          );
        });
        if (!variant) return null;
        return (
          <ColorOption
            key={variant.id}
            size="small"
            onMouseEnter={() => setSelectedVariant(variant)}
            onFocus={() => setSelectedVariant(variant)}
            value={value}
            isActive={variant.id === selectedVariant.id}
          />
        );
      })}
    </div>
  );
}

function CompareAtPrice({
  data,
  className,
}: {
  data: MoneyV2;
  className?: string;
}) {
  const {currencyNarrowSymbol, withoutTrailingZerosAndCurrency} =
    useMoney(data);

  const styles = clsx('strike', className);

  return (
    <span className={styles}>
      {currencyNarrowSymbol}
      {withoutTrailingZerosAndCurrency}
    </span>
  );
}

function Label({label, className}: {label: string; className?: string}) {
  return (
    <label
      className={clsx(
        'rounded-sm p-1.5 pb-1 text-right text-p2 leading-none tracking-widest',
        className,
      )}
    >
      {label}
    </label>
  );
}
