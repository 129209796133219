import {forwardRef} from 'react';
import {Link} from '~/components';
import {useState} from 'react';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SanityLink} from '~/types';
import {missingClass} from '~/lib/utils';
import {motion, AnimatePresence} from 'framer-motion';

export const ButtonIcon = forwardRef(
  (
    {
      as = 'button',
      className = '',
      variant = 'light',
      width = 'auto',
      icon,
      to,
      children,
      ...props
    }: {
      as?: React.ElementType;
      className?: string;
      variant?: 'light' | 'dark' | 'gray' | 'inline' | 'outline' | 'camo';
      width?: 'auto' | 'full';
      icon?: any;
      to: SanityLink;
      children: React.ReactNode;
      [key: string]: any;
    },
    ref,
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const Component = to ? Link : as;

    const baseButtonClasses =
      'flex justify-center items-center rounded-full uppercase text-button text-center xs:py-4 xs:px-4 hover:roll-activate focus:roll-activate disabled:text-opacity-50 group whitespace-nowrap';

    const variants = {
      light: `${baseButtonClasses} bg-white text-dark`,
      dark: `${baseButtonClasses} border border-dark/10 bg-dark text-silk`,
      gray: `${baseButtonClasses} bg-jet text-silk`,
      camo: `${baseButtonClasses} bg-camo text-silk`,
      outline: `${baseButtonClasses} border border-dark/20 bg-transparent text-dark`,
      inline: 'border-b border-dark/10 leading-none pb-1',
    };

    const widths = {
      auto: 'w-auto',
      full: 'w-full',
    };

    const styles = clsx(
      missingClass(className, 'bg-') && variants[variant],
      missingClass(className, 'w-') && widths[width],
      className,
    );

    return (
      <AnimatePresence>
        <Component
          className={styles}
          ref={ref}
          to={to}
          {...props}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              fixedWidth
              className="group-hover:scale-120 transition-transform"
            />
          )}
          <motion.span
            className="overflow-hidden"
            animate={{
              opacity: isHovered ? 1 : 0,
              width: isHovered ? 'auto' : 0,
            }}
            initial={{
              opacity: 0,
              width: 0,
            }}
            exit={{
              width: 0,
              opacity: 0,
            }}
          >
            <span className={clsx('mt-1 ml-1 block')}>{children}</span>
          </motion.span>
        </Component>
      </AnimatePresence>
    );
  },
);
