import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/sharp-regular-svg-icons';
import clsx from 'clsx';
import {HTMLAttributes} from 'react';
import {twMerge} from 'tailwind-merge';

type Props = HTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  iconClassName?: string;
};

export function ArrowButton(props: Props) {
  const {className, iconClassName, disabled, ...rest} = props;

  return (
    <button
      className={clsx(
        twMerge(
          'bg-cream flex items-center justify-center font-bold duration-200 hover:bg-opacity-70',
          className,
        ),
        {
          'cursor-default opacity-50': disabled,
        },
      )}
      type="button"
      {...rest}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        size={'xl'}
        className={clsx(iconClassName)}
      />
    </button>
  );
}
