/* eslint-disable eslint-comments/disable-enable-pair */

declare global {
  interface Window {
    gtag: (
      option: string,
      gtagId: string,
      options: Record<string, unknown>,
    ) => void;
  }
}

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
const pageview = (url: string, trackingId: string) => {
  if (!window.gtag) {
    return;
  }
  window.gtag('config', trackingId, {
    page_path: url,
  });
};

/**
 * @example
 * https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
const event = ({action, category, label, value}: Record<string, string>) => {
  if (!window.gtag) {
    return;
  }
  window.gtag('event', action, {
    ...(category && {event_category: category}),
    ...(label && {event_label: label}),
    ...(value && {value}),
  });
};

export const gtag = {
  pageview,
  event,
};

// scripts are loaded in the root
export function GoogleAnalytics({gtagId}: {gtagId: string}) {
  if (!gtagId) return null;

  return (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}
      />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtagId}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
}
