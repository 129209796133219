import clsx from 'clsx';

export function SizeOption({
  value,
  isActive,
  isSoldOut,
  index,
  className,
  ...props
}: {
  value: string;
  isActive: boolean;
  isSoldOut?: boolean;
  index: number;
  className?: string;
  [key: string]: any;
}) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        'relative flex h-9 w-14 items-center justify-center overflow-hidden border-black pt-0.5 text-p3 uppercase leading-none',
        {
          'border-l': index !== 0,
          'bg-black text-white': isActive,
          'before:absolute before:top-[18px] before:-left-[10%] before:h-[1px] before:w-[120%] before:rotate-[-33deg] before:bg-spanishGray ':
            isSoldOut,
        },
      )}
    >
      <span
        className={clsx(' p-1', {
          'bg-white': !isActive,
          'z-10 text-spanishGray': isSoldOut,
        })}
      >
        {value}
      </span>
    </span>
  );
}
