import type {PortableTextMarkDefinition} from '@portabletext/types';
import {SanityProductWithVariant, SanityShopifyProducts} from '~/types';
import clsx from 'clsx';
import {Link} from '~/components/elements';

const LinkProductAnnotation = ({
  value,
  children,
  shopifyProducts,
}: {
  value: PortableTextMarkDefinition & {
    linkAction: 'addToCart' | 'buyNow' | 'link';
    productWithVariant: SanityProductWithVariant;
    quantity?: number;
  };
  children: React.ReactNode;
  shopifyProducts: SanityShopifyProducts | undefined;
}) => {
  const {productWithVariant} = value;

  //TODO: Extend this to handle the other link actions

  // const storefrontProduct = getRelevantShopifyProducts(
  //   productWithVariant,
  //   shopifyProducts,
  // );

  // if (!storefrontProduct) {
  //   return <>{children}</>;
  // }

  return (
    <Link
      className={clsx(
        'inline-flex items-center transition-opacity duration-200',
        'hover:opacity-60',
      )}
      to={`/products/${productWithVariant?.slug}`}
    >
      <>{children}</>
    </Link>
  );
};

export default LinkProductAnnotation;
