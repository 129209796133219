import {useCallback, useState, useRef} from 'react';
import {Swiper, SwiperSlide, SwiperRef, SwiperProps} from 'swiper/react';
import {FreeMode} from 'swiper';
import {ArrowButton, PortableText} from '~/components';
import {PortableTextBlock} from '@sanity/types';
import clsx from 'clsx';

export function Carousel({
  items,
  title,
  description,
  options,
  as = 'div',
  prop = 'item',
  className,
}: {
  items: any[] | undefined;
  title: string;
  description?: PortableTextBlock[];
  options?: SwiperProps;
  as: React.ElementType;
  prop?: string;
  className?: string;
}) {
  const swiperRef = useRef<SwiperRef>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handlePrevious = useCallback(() => {
    swiperRef.current?.swiper?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef.current?.swiper?.slideNext();
  }, [swiperRef]);

  if (!items?.length) return null;

  const Component = as;

  const defaults = {
    slidesPerView: 1.2,
    spaceBetween: 20,
    loop: false,
    watchOverflow: true,
    centeredSlides: false,
    freeMode: {
      enabled: false,
      sticky: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2.5,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };

  const params = {...defaults, ...options};

  return (
    <div className="mx-auto max-w-site">
      {!description && (
        <div className="mb-6 flex items-center justify-between md:mb-10">
          {title && <h2 className="text-h5 md:text-h6">{title}</h2>}

          <div className="ml-auto flex items-center gap-6">
            <ArrowButton onClick={handlePrevious} iconClassName="rotate-180" />
            <ArrowButton onClick={handleNext} />
          </div>
        </div>
      )}

      <div
        className={clsx(
          'flex flex-col items-center gap-6 md:grid md:grid-cols-[1fr_75%]',
        )}
      >
        {title && description && (
          <div>
            <h2 className="text-d4 uppercase lg:text-d7">{title}</h2>
            <PortableText value={description} className="portable-text" />
          </div>
        )}

        <div className="flex max-w-full items-center">
          <div className="ml-auto flex items-center gap-6">
            <ArrowButton
              onClick={handlePrevious}
              iconClassName="rotate-180 px-2 py-6"
            />
          </div>
          <Swiper
            ref={swiperRef}
            autoHeight={false}
            modules={[FreeMode]}
            // TODO: add styling that keeps slides at same height while loading
            className={clsx(
              'edge-mask transition-opacity',
              isLoading ? 'opacity-0' : 'opacity-100',
              description ? '' : 'swiper-overflow',
            )}
            onSwiper={() => setIsLoading(false)}
            {...params}
          >
            {items?.map((item, i) => (
              <SwiperSlide
                key={item?._id || item?.id || item?._key}
                className={className}
              >
                <Component {...{[prop]: item}} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="ml-auto flex items-center gap-6">
            <ArrowButton onClick={handleNext} iconClassName="px-2 py-6" />
          </div>
        </div>
      </div>
    </div>
  );
}
