// prettier-ignore
import type { PortableTextBlock, PortableTextMarkDefinition } from '@portabletext/types';
import {Link} from '~/components';
import clsx from 'clsx';
import {SanityLinkInternal} from '~/types';

type Props = PortableTextBlock & {
  value: PortableTextMarkDefinition & SanityLinkInternal;
  children: React.ReactNode;
};

export default function LinkInternalAnnotation({children, value}: Props) {
  if (!value?.to) {
    return null;
  }

  return (
    <Link
      className={clsx(
        'inline-flex items-center transition-opacity duration-200',
        'hover:opacity-60',
      )}
      to={value?.to}
    >
      <>{children}</>
    </Link>
  );
}
