import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';
import {ProductCard, SanityImage} from '~/components';
import {getRelevantShopifyProducts} from '~/lib/utils';
import {SanitySectionProductGroup, SanityShopifyProducts} from '~/types';

export function ProductGroup({
  section,
  shopifyProducts,
}: {
  section: SerializeFrom<SanitySectionProductGroup>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
}) {
  const {title, image, products: sanityProducts} = section;

  const products = getRelevantShopifyProducts(sanityProducts, shopifyProducts);

  if (!products?.length) return null;

  return (
    <section className="section-px my-15 lg:my-20 ">
      <div className="mx-auto flex max-w-site flex-col border-b pb-15 lg:border-0 lg:pb-0">
        <h2 className="mb-5 text-h6">{title}</h2>

        <div className="flex flex-col gap-5 lg:flex-row">
          <div className="relative pt-5 ">
            <div className="top-[calc(var(--height-nav)+5.2rem)] aspect-[5/6] max-h-[1150px] w-full lg:sticky lg:h-screen-no-nav lg:w-auto ">
              <SanityImage
                className="fadeIn aspect-[5/6] w-full rounded"
                crop={image?.crop}
                hotspot={image?.hotspot}
                objectFit="cover"
                layout="fill"
                width={920}
                sizes={[550, 920]}
                src={image?.asset?._ref}
              />
            </div>
          </div>

          <div
            className={clsx(
              'swimlane hiddenScroll w-full',
              'p-0 lg:grid-flow-row lg:grid-cols-2 lg:gap-5 lg:overflow-x-auto lg:pt-5',
            )}
          >
            {products &&
              products.map((product) => (
                <ProductCard
                  product={product}
                  key={product.id}
                  className="aspect-[5/6] w-[70vw] snap-center md:w-[50vw] lg:w-full"
                />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
