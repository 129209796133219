export function Dennis() {
  const url = 'https://dennissnellenberg.com';
  const title = 'dennis';

  return (
    <div className="flex items-center justify-center gap-3 before:content-['ꞏ']">
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="hover:text-[#455CE9]"
      >
        des@{title}
      </a>
    </div>
  );
}
