/* eslint-disable eslint-comments/disable-enable-pair */
declare global {
  interface Window {
    fbq: (
      type: 'track' | 'init',
      action: string,
      options?: Record<string, string>,
    ) => void;
  }
}

const pageview = (url: string, trackingId: string) => {
  if (!window.fbq) return;
  window.fbq('track', 'PageView');
};

/**
 * @example
 * https://developers.facebook.com/docs/meta-pixel/reference#standard-events
 */
const event = ({
  action,
  options = {},
}: {
  action: string;
  options: Record<string, string>;
}) => {
  if (!window.fbq) return;
  window.fbq('track', action, options);
};

export const fbq = {
  pageview,
  event,
};

export function MetaPixel({pixelId}: {pixelId: string}) {
  if (!pixelId) return null;

  return (
    <>
      <script async src="https://connect.facebook.net/en_US/fbevents.js" />
      <script
        async
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,n){
            if(f.fbq)return;
            n=f.fbq=function(){
              n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)
            };
            if(!f._fbq)f._fbq=n;
            n.push=n;
            n.loaded=!0;
            n.version='2.0';
            n.queue=[];
          }(window);

          fbq('init', '${pixelId}');
          fbq('track', 'PageView');
        `,
        }}
      />
    </>
  );
}
