import {Carousel, SanityImage} from '~/components';
import {SanityAssetImage, SanitySectionGallery} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function Gallery({
  section,
}: {
  section: SerializeFrom<SanitySectionGallery>;
}) {
  const {title, images} = section;

  return (
    <section className="my-15 overflow-hidden lg:my-20">
      <div className="section-px relative">
        <Carousel items={images} title={title} as={GalleryImage} prop="image" />
      </div>
    </section>
  );
}

function GalleryImage({image}: {image: SanityAssetImage}) {
  return (
    <div className="card-image group aspect-[4/5] max-w-xl overflow-clip">
      {image && (
        <SanityImage
          className="fadeIn"
          crop={image?.crop}
          hotspot={image?.hotspot}
          width={440}
          height={550}
          objectFit="contain "
          layout="fill"
          sizes={[880, 1320]}
          src={image?.asset?._ref}
        />
      )}
    </div>
  );
}
