import {Carousel, CollectionCard} from '~/components';
import {SanitySectionCollections} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function Collections({
  section,
}: {
  section: SerializeFrom<SanitySectionCollections>;
}) {
  const {title, selected} = section;

  return (
    <section className="relative my-15 flex w-full flex-col overflow-hidden md:my-20">
      <div className="section-px">
        <Carousel
          items={selected}
          title={title}
          as={CollectionCard}
          prop="collection"
        />
      </div>
    </section>
  );
}
