import {
  CountrySelector,
  SanityImage,
  FooterMenu,
  LegalMenu,
  SiteCredits,
  SocialIcons,
  Newsletter,
  Heading,
  IconMadeInBritain,
  Logo,
  Link,
  Contact,
} from '~/components';
import {
  SanityMenus,
  SanitySocialLink,
  SanityAssetImage,
  ContactDetails,
} from '~/types';

export function Footer({
  menus,
  social,
  contact,
}: {
  menus?: SanityMenus;
  social?: SanitySocialLink[];
  footerTexture?: SanityAssetImage;
  contact?: ContactDetails;
}) {
  return (
    <footer
      role="contentinfo"
      className={`section-px relative  overflow-hidden border-t border-white/10 bg-dark pt-8 text-contrast md:pt-20`}
    >
      <div className="mx-auto grid w-full max-w-site grid-flow-row grid-cols-12 items-start gap-y-8 md:gap-y-20">
        <div className="col-span-12 h-auto justify-self-start md:col-span-6">
          <Logo />
        </div>

        <div className="col-span-12 h-auto md:col-span-6 md:justify-self-end">
          <IconMadeInBritain
            className={'h-auto w-40 max-w-full text-spanishGray md:w-56'}
          />
        </div>

        <FooterMenu
          menu={menus?.footerGeneral}
          className="col-span-12 h-full border-b border-white/10 pb-8 md:col-span-6 md:border-b-0 md:border-l md:px-12 lg:col-span-3"
        />
        <FooterMenu
          menu={menus?.footerSupport}
          className="col-span-12 h-full border-b border-white/10 pb-8 md:col-span-6 md:border-b-0 md:border-l md:border-r md:px-12 lg:col-span-3 lg:border-r-0"
        />

        <div className="col-span-12 h-full border-b border-white/10 pb-8 md:col-span-6 md:border-b-0 md:border-l md:px-12 lg:col-span-3">
          <Contact contact={contact} />
        </div>

        <div className="col-span-12 row-start-3 flex h-full border-b border-t border-white/10 md:col-span-6 md:row-start-auto md:items-center md:justify-center md:border-b-0 md:border-l md:border-r md:border-t-0 lg:col-span-3">
          <Link className="py-4 text-h4 uppercase md:px-12" to="?modal=enquire">
            <span className="roll roll-white hover:roll-activate block">
              Make an enquiry
            </span>
          </Link>
        </div>

        {/* <div className="col-span-12 flex flex-col gap-5 md:col-span-8 md:gap-7 lg:col-span-5">
          <Heading as="h3" size="copy">
            Join our newsletter
          </Heading>
          <Newsletter source="footer" />
        </div> */}

        <section className="col-span-12 flex grid-flow-row grid-cols-2 flex-col-reverse justify-start gap-5 pb-10 text-left lg:grid lg:items-center lg:gap-4">
          <div className="flex justify-between self-stretch border-t border-white/10 pt-8 lg:border-0 lg:pt-0">
            <SiteCredits />
          </div>
          <SocialIcons platforms={social} className="justify-self-end" />
          {/* <LegalMenu menu={menus?.legal} /> */}
          {/* <CountrySelector /> */}
        </section>
      </div>
    </footer>
  );
}
