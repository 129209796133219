import {flattenConnection} from '@shopify/hydrogen';
import type {Order} from '@shopify/hydrogen/storefront-api-types';
import {Heading, Text, Link} from '~/components';
import {statusMessage} from '~/lib/utils';

export function OrderCard({order}: {order: Order}) {
  if (!order?.id) return null;
  const [legacyOrderId, key] = order!.id!.split('/').pop()!.split('?');
  const lineItems = flattenConnection(order?.lineItems);

  return (
    <li className="grid rounded border border-white/20 text-center">
      <Link
        className="grid items-center gap-4 p-4 md:grid-cols-2 md:gap-6 md:p-6"
        to={`/account/orders/${legacyOrderId}?${key}`}
        prefetch="intent"
      >
        {lineItems[0].variant?.image && (
          <div className="card-image aspect-square bg-sonicSilver/5">
            <img
              width={168}
              height={168}
              className="fadeIn cover w-full"
              alt={lineItems[0].variant?.image?.altText ?? 'Order image'}
              src={lineItems[0].variant?.image.url}
            />
          </div>
        )}
        <div
          className={`flex-col justify-center text-left ${
            !lineItems[0].variant?.image && 'md:col-span-2'
          }`}
        >
          <Heading as="h3" format size="copy">
            {lineItems.length > 1
              ? `${lineItems[0].title} +${lineItems.length - 1} more`
              : lineItems[0].title}
          </Heading>
          <dl className="grid-gap-1 grid">
            <dt className="sr-only">Order ID</dt>
            <dd>
              <p className="text-p1 text-sonicSilver">
                Order No. {order.orderNumber}
              </p>
            </dd>
            <dt className="sr-only">Order Date</dt>
            <dd>
              <p className="text-p1 text-sonicSilver">
                {new Date(order.processedAt).toDateString()}
              </p>
            </dd>
            <dt className="sr-only">Fulfillment Status</dt>
            <dd className="mt-2">
              <span
                className={`rounded-full px-3 py-1 text-p2 font-medium ${
                  order.fulfillmentStatus === 'FULFILLED'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-primary/5 text-primary/50'
                }`}
              >
                <span className=" text-p1">
                  {statusMessage(order.fulfillmentStatus)}
                </span>
              </span>
            </dd>
          </dl>
        </div>
      </Link>
      <div className="self-end border-t">
        <Link
          className="block w-full p-2 text-center"
          to={`/account/orders/${legacyOrderId}?${key}`}
          prefetch="intent"
        >
          <span className="ml-3 text-sonicSilver">View Details</span>
        </Link>
      </div>
    </li>
  );
}
