import clsx from 'clsx';
import {Link, SanityImage} from '~/components';
import {ImageWithProductHotspots} from '~/components';
import {SanityModuleImage} from '~/types';

// create an image dimensions map based on type, number of images and the full width setting
const imageDimensionsMap = {
  1: {
    full: {
      width: 1920,
      height: 1080,
    },
    wide: {
      width: 1727,
    },
    content: {
      width: 880,
    },
  },
  2: {
    full: {
      width: 928,
    },
    wide: {
      width: 832,
    },
    content: {
      width: 408,
    },
  },
};

export function ModularImage({
  module,
  count,
  width,
}: {
  module: SanityModuleImage;
  count?: number;
  width?: 'full' | 'wide' | 'content';
}) {
  if (!module.image) {
    return null;
  }

  return (
    <div className="relative">
      {module.variant === 'default' && (
        <ImageContent module={module} count={count} width={width} />
      )}

      {module.variant === 'callToAction' && module.callToAction?.link && (
        <Link className="group" to={module.callToAction.link}>
          <ImageContent module={module} />
        </Link>
      )}

      {/* Caption */}
      {/* {module.variant === 'caption' && module.caption && (
        <div className="mt-2 max-w-[35rem] text-sm leading-caption text-dark/50">
          {module.caption}
        </div>
      )} */}
      {/* Product hotspots */}
      {/* {module.variant === 'productHotspots' && (
        <>


            <ImageWithProductHotspots
              content={component}
              shopifyProducts={shopifyProducts}
            />
        </>
      )} */}
      {/* Product tags */}
      {/* {module.variant === 'productTags' && (
        <div className="mt-2 flex flex-wrap gap-x-1 gap-y-2">
          {module.productTags?.map((product, index) => (
            <ProductTag
              key={product._key}
              storefrontProduct={storefrontProducts[index]}
            />
          ))}
        </div>
      )} */}
    </div>
  );
}

const ImageContent = ({
  module,
  count,
  width,
}: {
  module: SanityModuleImage;
  count: number;
  width?: 'full' | 'wide' | 'content';
}) => {
  const image = module.image;
  const w = imageDimensionsMap[count][width].width;
  const h = imageDimensionsMap[count][width].height;

  return (
    <div
      className={clsx(
        'relative w-full overflow-hidden duration-500 ease-out',
        width !== 'full' && 'rounded-md',
      )}
    >
      <SanityImage
        className="fadeIn w-full"
        crop={image?.crop}
        hotspot={image?.hotspot}
        width={w || 880}
        height={h || ''}
        objectFit="cover"
        layout="responsive"
        sizes={[880, 1320]}
        src={image?.asset?._ref}
      />

      {/* Call to action */}
      {/* {module.variant === 'callToAction' && (
        <div
          className={clsx(
            'absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black bg-opacity-20 duration-500 ease-out',
            'group-hover:bg-opacity-30',
          )}
        >
          <div className="mt-[1em] flex flex-col items-center gap-5"> */}
      {/* Title */}
      {/* <div
              className={clsx(
                'max-w-[30rem] text-xl text-white', //
                'lg:text-2xl',
                'xl:text-3xl',
              )}
            >
              {module.callToAction?.title}
            </div> */}

      {/* Button */}
      {/* {module.callToAction?.link && (
              <div
                className={clsx(
                  DEFAULT_BUTTON_SOLID_STYLES,
                  'pointer-events-none bg-white text-dark',
                )}
              >
                {module.callToAction.title}
              </div>
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};
