import {
  FeaturedSection,
  Button,
  PageHeader,
  Text,
  FeaturedProducts,
} from '~/components';

export function NotFound({type = 'page'}: {type?: string}) {
  const heading = `We’ve lost this ${type}`;
  const description = `We couldn’t find the ${type} you’re looking for. Try checking the URL or heading back to the home page.`;

  return (
    <div className="py-20 px-6 sm:px-8 md:px-10">
      <div className="mx-auto flex max-w-site flex-col items-start gap-14">
        <header className="flex flex-col gap-3 ">
          <h1 className="text-h5">{heading}</h1>
          <p className="max-w-prose-narrow text-p3"> {description}</p>
          <Button width="auto" variant="dark" to={'/'}>
            Take me home
          </Button>
        </header>
        <div>
          <FeaturedProducts
            count={4}
            layout="page"
            heading="How about these instead?"
            sortKey="BEST_SELLING"
          />
        </div>
      </div>
    </div>
  );
}
