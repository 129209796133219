import {PortableText, SanityImage} from '~/components';
import {SanitySectionOrderFeatures} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';

export function OrderFeatures({
  section,
}: {
  section: SerializeFrom<SanitySectionOrderFeatures>;
}) {
  const {features} = section;

  return (
    <section className="grid gap-12 md:grid-cols-2 md:gap-0">
      {features.map((feature, i) => (
        <div
          key={feature._key}
          className={clsx('flex flex-col text-center', {
            'md:pr-18': i === 0,
            'border-sonicSilver md:border-l md:pl-18': i === 1,
          })}
        >
          <h3 className="text-h4 uppercase">{feature.title}</h3>
          <PortableText
            value={feature.body}
            className="portable-text mx-auto mb-10 max-w-sm text-p3 text-white"
          />
          <div className="mt-auto border  border-white p-8">
            <div className="relative aspect-square">
              <SanityImage
                crop={feature?.image.crop}
                hotspot={feature?.image.hotspot}
                width={1000}
                height={1000}
                objectFit="cover"
                layout="fill"
                sizes={[600, 1000]}
                src={feature?.image.asset?._ref}
              />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}
