import {Form} from '@remix-run/react';
import type {
  Customer,
  MailingAddress,
} from '@shopify/hydrogen/storefront-api-types';
import {Button, Link, Text} from '~/components';

export function AccountAddressBook({
  customer,
  addresses,
}: {
  customer: Customer;
  addresses: MailingAddress[];
}) {
  return (
    <>
      <div className="grid w-full gap-5 md:gap-10 ">
        <h2 className="text-h5">Address Book</h2>
        <div>
          {!addresses?.length && (
            <p className="mb-1 text-p4">
              You haven&apos;t saved any addresses yet.
            </p>
          )}
          <div className="mt-5 w-48">
            <Button
              to="add"
              className="mt-2 mb-6 w-full text-p3"
              variant="dark"
            >
              Add an Address
            </Button>
          </div>
          {Boolean(addresses?.length) && (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
              {customer.defaultAddress && (
                <Address address={customer.defaultAddress} defaultAddress />
              )}
              {addresses
                .filter((address) => address.id !== customer.defaultAddress?.id)
                .map((address) => (
                  <Address key={address.id} address={address} />
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Address({
  address,
  defaultAddress,
}: {
  address: MailingAddress;
  defaultAddress?: boolean;
}) {
  return (
    <div className="flex flex-col rounded border border-gray-200 p-6 lg:p-8">
      {defaultAddress && (
        <div className="mb-3 flex flex-row">
          <span className="rounded-full bg-primary/20 px-3 py-1 text-p2 font-medium text-primary/50">
            Default
          </span>
        </div>
      )}
      <ul className="flex-1 flex-row">
        {(address.firstName || address.lastName) && (
          <li>
            {'' +
              (address.firstName && address.firstName + ' ') +
              address?.lastName}
          </li>
        )}
        {address.formatted &&
          address.formatted.map((line: string) => <li key={line}>{line}</li>)}
      </ul>

      <div className="mt-6 flex flex-row items-baseline font-medium">
        <Link
          to={`/account/address/${encodeURIComponent(address.id)}`}
          className="text-left text-p3 underline"
          prefetch="intent"
        >
          Edit
        </Link>
        <Form action="address/delete" method="delete">
          <input type="hidden" name="addressId" value={address.id} />
          <button className="ml-6 text-left text-p3 text-primary/50">
            Remove
          </button>
        </Form>
      </div>
    </div>
  );
}
