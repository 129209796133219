import clsx from 'clsx';
import {useRef, useState} from 'react';
import {useScroll} from 'react-use';
import {flattenConnection, Image, Money} from '@shopify/hydrogen';
import {
  Button,
  Heading,
  IconRemove,
  Text,
  Link,
  FeaturedProducts,
  ColorOption,
} from '~/components';
import {getInputStyleClasses} from '~/lib/utils';
import type {
  Cart as CartType,
  CartCost,
  CartLine,
  CartLineUpdateInput,
} from '@shopify/hydrogen/storefront-api-types';
import {useFetcher} from '@remix-run/react';
import {CartAction} from '~/types';

type Layouts = 'page' | 'drawer';

export function Cart({
  layout,
  onClose,
  cart,
}: {
  layout: Layouts;
  onClose?: () => void;
  cart: CartType | null;
}) {
  const linesCount = Boolean(cart?.lines?.edges?.length || 0);

  return (
    <>
      <CartEmpty hidden={linesCount} onClose={onClose} layout={layout} />
      <CartDetails cart={cart} layout={layout} />
    </>
  );
}

export function CartDetails({
  layout,
  cart,
}: {
  layout: Layouts;
  cart: CartType | null;
}) {
  // @todo: get optimistic cart cost
  const cartHasItems = !!cart && cart.totalQuantity > 0;

  const container = {
    drawer: 'grid grid-cols-1 h-screen-no-nav grid-rows-[1fr_auto]',
    page: 'w-full pb-12 grid md:grid-cols-2 md:items-start gap-8 md:gap-8 lg:gap-12',
  };

  return (
    <div className={container[layout]}>
      <CartLines lines={cart?.lines} layout={layout} />
      {/* <p className="p-4 px-6 text-p2 text-sonicSilver md:px-12">
        Reserving your edition number is effectively like paying a deposit. When
        you reserve an edition number, we will send you an invoice of the
        remaining amount of the purchase price. This reservation is valid for 2
        weeks, if you haven&apos;t paid the balance for the full watch purchase
        within the 14 days, you will lose your reserved edition number.
      </p> */}
      {cartHasItems && (
        <CartSummary cost={cart.cost} layout={layout}>
          {/* <CartDiscounts discountCodes={cart.discountCodes} /> */}
          <CartCheckoutActions checkoutUrl={cart.checkoutUrl} />
        </CartSummary>
      )}
    </div>
  );
}

/**
 * Temporary discount UI
 * @param discountCodes the current discount codes applied to the cart
 * @todo rework when a design is ready
 */
function CartDiscounts({
  discountCodes,
}: {
  discountCodes: CartType['discountCodes'];
}) {
  const codes = discountCodes?.map(({code}) => code).join(', ') || null;

  return (
    <>
      {/* Have existing discount, display it with a remove option */}
      <dl className={codes ? 'grid' : 'hidden'}>
        <div className="flex items-center justify-between text-p3 font-medium">
          <dt>Discount(s)</dt>
          <div className="flex items-center justify-between">
            <UpdateDiscountForm>
              <button>
                <IconRemove
                  aria-hidden="true"
                  style={{height: 18, marginRight: 4}}
                />
              </button>
            </UpdateDiscountForm>
            <dd>{codes}</dd>
          </div>
        </div>
      </dl>

      {/* No discounts, show an input to apply a discount */}
      <UpdateDiscountForm>
        <div
          className={clsx(
            codes ? 'hidden' : 'flex',
            'items-center justify-between gap-4 text-p3',
          )}
        >
          <input
            className={getInputStyleClasses()}
            type="text"
            name="discountCode"
            placeholder="Discount code"
          />
          <button className="flex justify-end whitespace-nowrap font-medium">
            Apply Discount
          </button>
        </div>
      </UpdateDiscountForm>
    </>
  );
}

function UpdateDiscountForm({children}: {children: React.ReactNode}) {
  const fetcher = useFetcher();
  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.UPDATE_DISCOUNT}
      />
      {children}
    </fetcher.Form>
  );
}

function CartLines({
  layout = 'drawer',
  lines: cartLines,
}: {
  layout: Layouts;
  lines: CartType['lines'] | undefined;
}) {
  const currentLines = cartLines ? flattenConnection(cartLines) : [];
  const scrollRef = useRef(null);
  const {y} = useScroll(scrollRef);

  const className = clsx([
    y > 0 ? 'border-t' : '',
    layout === 'page'
      ? 'flex-grow md:translate-y-4'
      : 'px-6 pb-6 sm-max:pt-2 overflow-auto transition md:px-12',
  ]);

  return (
    <section
      ref={scrollRef}
      aria-labelledby="cart-contents"
      className={className}
    >
      <ul className="grid gap-5">
        {currentLines.map((line) => (
          <CartLineItem key={line.id} line={line as CartLine} />
        ))}
      </ul>
    </section>
  );
}

function CartCheckoutActions({checkoutUrl}: {checkoutUrl: string}) {
  const [hasTerms, setHasTerms] = useState(false);

  const handleTermsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasTerms(event.target.checked);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (hasTerms) {
      window.location.href = checkoutUrl;
    }
  };

  return (
    <div className="mt-2 flex flex-col">
      <form onSubmit={handleSubmit}>
        <div className="mb-4 flex items-center text-p3">
          <input
            type="checkbox"
            name="termsAndConditions"
            className="mr-4 h-4 w-4"
            checked={hasTerms}
            required
            onChange={handleTermsCheck}
          />
          <label htmlFor="termsAndConditions">
            I have read and agree to the{' '}
            <Link to="/terms-of-use" target="_blank" className="underline">
              Terms and Conditions
            </Link>
          </label>
        </div>
        <Button type="submit" width="full" variant="dark">
          Checkout
        </Button>
      </form>
    </div>
  );
}

function CartSummary({
  cost,
  layout,
  children = null,
}: {
  children?: React.ReactNode;
  cost: CartCost;
  layout: Layouts;
}) {
  const summary = {
    drawer: 'grid gap-4 p-6 border-t md:px-12',
    page: 'sticky top-nav grid gap-6 p-4 md:px-6 md:translate-y-4 bg-primary/5 rounded w-full',
  };

  return (
    <section aria-labelledby="summary-heading" className={summary[layout]}>
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>
      <dl className="grid">
        <div className="flex items-center justify-between text-p3 font-medium">
          <dt>Subtotal</dt>
          <dd data-test="subtotal">
            {cost?.subtotalAmount?.amount ? (
              <Money data={cost?.subtotalAmount} />
            ) : (
              '-'
            )}
          </dd>
        </div>
      </dl>
      {children}
    </section>
  );
}

function CartLineItem({line}: {line: CartLine}) {
  if (!line?.id) return null;

  const {id, quantity, merchandise} = line;

  if (typeof quantity === 'undefined' || !merchandise?.product) return null;

  return (
    <li
      key={id}
      className="flex gap-4 border-t pt-5 first:border-none first:pt-0"
    >
      <div className="flex-shrink-0">
        {merchandise.image && (
          <Image
            width={220}
            data={merchandise.image}
            className="aspect-[5/6] w-[100px] rounded object-cover"
            alt={merchandise.title}
          />
        )}
      </div>

      <div className="flex flex-grow justify-between text-p3">
        <div className="flex flex-col">
          <h3 className="text-h3">
            {merchandise?.product?.handle ? (
              <Link to={`/products/${merchandise.product.handle}`}>
                {merchandise?.product?.title || ''}
              </Link>
            ) : (
              <Text>{merchandise?.product?.title || ''}</Text>
            )}
          </h3>

          <div className="grid pb-2">
            {(merchandise?.selectedOptions || []).map((option) => {
              switch (option.name) {
                case 'Color':
                  return (
                    <div
                      key={option.name}
                      className="row-start-1 flex items-baseline gap-1 pb-1 capitalize"
                    >
                      <ColorOption value={option.value} />
                      {option.value}
                    </div>
                  );

                case 'Title':
                  return null;

                case 'Size':
                default:
                  return (
                    <div key={option.name}>
                      <span className="text-sonicSilver">{option.value}</span>
                    </div>
                  );
              }
            })}
          </div>

          <div className="mt-auto flex items-center gap-2">
            {/* <div className="flex justify-start">
              <CartLineQuantityAdjust line={line} />
            </div> */}
            <ItemRemoveButton lineIds={[id]} />
          </div>
        </div>
        <span className="self-end">
          <CartLinePrice line={line} as="span" />
        </span>
      </div>
    </li>
  );
}

function ItemRemoveButton({lineIds}: {lineIds: CartLine['id'][]}) {
  const fetcher = useFetcher();

  return (
    <fetcher.Form action="/cart" method="post">
      <input
        type="hidden"
        name="cartAction"
        value={CartAction.REMOVE_FROM_CART}
      />
      <input type="hidden" name="linesIds" value={JSON.stringify(lineIds)} />
      <button
        className="flex h-8 w-8 items-center justify-center rounded border"
        type="submit"
      >
        <span className="sr-only">Remove</span>
        <IconRemove aria-hidden="true" />
      </button>
    </fetcher.Form>
  );
}

// function CartLineQuantityAdjust({line}: {line: CartLine}) {
//   if (!line || typeof line?.quantity === 'undefined') return null;
//   const {id: lineId, quantity} = line;
//   const prevQuantity = Number(Math.max(0, quantity - 1).toFixed(0));
//   const nextQuantity = Number((quantity + 1).toFixed(0));

//   return (
//     <>
//       <label htmlFor={`quantity-${lineId}`} className="sr-only">
//         Quantity, {quantity}
//       </label>
//       <div className="flex items-center rounded border border-sonicSilver">
//         <UpdateCartButton lines={[{id: lineId, quantity: prevQuantity}]}>
//           <button
//             name="decrease-quantity"
//             aria-label="Decrease quantity"
//             className="h-8 w-8 text-white/50 transition hover:text-white disabled:text-white/10"
//             value={prevQuantity}
//             disabled={quantity <= 1}
//           >
//             <span>&#8722;</span>
//           </button>
//         </UpdateCartButton>

//         <div className="px-2 text-center" data-test="item-quantity">
//           {quantity}
//         </div>

//         <UpdateCartButton lines={[{id: lineId, quantity: nextQuantity}]}>
//           <button
//             className="h-8 w-8 text-white/50 transition hover:text-white"
//             name="increase-quantity"
//             value={nextQuantity}
//             aria-label="Increase quantity"
//           >
//             <span>&#43;</span>
//           </button>
//         </UpdateCartButton>
//       </div>
//     </>
//   );
// }

// function UpdateCartButton({
//   children,
//   lines,
// }: {
//   children: React.ReactNode;
//   lines: CartLineUpdateInput[];
// }) {
//   const fetcher = useFetcher();

//   return (
//     <fetcher.Form action="/cart" method="post">
//       <input type="hidden" name="cartAction" value={CartAction.UPDATE_CART} />
//       <input type="hidden" name="lines" value={JSON.stringify(lines)} />
//       {children}
//     </fetcher.Form>
//   );
// }

function CartLinePrice({
  line,
  priceType = 'regular',
  ...passthroughProps
}: {
  line: CartLine;
  priceType?: 'regular' | 'compareAt';
  [key: string]: any;
}) {
  if (!line?.cost?.amountPerQuantity || !line?.cost?.totalAmount) return null;

  const moneyV2 =
    priceType === 'regular'
      ? line.cost.totalAmount
      : line.cost.compareAtAmountPerQuantity;

  if (moneyV2 == null) {
    return null;
  }

  return <Money withoutTrailingZeros {...passthroughProps} data={moneyV2} />;
}

export function CartEmpty({
  hidden = false,
  layout = 'drawer',
  onClose,
}: {
  hidden: boolean;
  layout?: Layouts;
  onClose?: () => void;
}) {
  const scrollRef = useRef(null);
  const {y} = useScroll(scrollRef);

  const container = {
    drawer: clsx([
      'content-start gap-4 px-6 pb-8 transition overflow-y-scroll md:gap-12 md:px-12 h-screen-no-nav md:pb-12',
      y > 0 ? 'border-t' : '',
    ]),
    page: clsx([
      hidden ? '' : 'grid',
      `pb-12 w-full md:items-start gap-4 md:gap-8 lg:gap-12`,
    ]),
  };

  return (
    <div ref={scrollRef} className={container[layout]} hidden={hidden}>
      <section className="grid gap-6">
        <Text format>
          Looks like you haven&rsquo;t added anything yet, let&rsquo;s get you
          started!
        </Text>
        <div>
          <Button onClick={onClose}>Continue shopping</Button>
        </div>
      </section>
      {/* <section className="grid gap-8 pt-16">
        <FeaturedProducts
          count={4}
          heading="Shop Best Sellers"
          layout={layout}
          onClose={onClose}
          sortKey="BEST_SELLING"
        />
      </section> */}
    </div>
  );
}
