import type {Customer} from '@shopify/hydrogen/storefront-api-types';
import {Link} from '~/components';

export function AccountDetails({customer}: {customer: Customer}) {
  const {firstName, lastName, email, phone} = customer;

  return (
    <>
      <div className="grid w-full gap-5  md:gap-10 ">
        <h2 className="text-h5">Account Details</h2>
        <div className="rounded border border-gray-200 bg-black p-6 lg:p-8">
          <div className="flex">
            <h3 className="text-base flex-1 font-bold">Profile & Security</h3>
            <Link
              prefetch="intent"
              className="text-p3 font-normal underline"
              to="/account/profile/edit"
            >
              Edit
            </Link>
          </div>
          <div className="mt-4 text-p3 text-primary/50">Name</div>
          <p className="mt-1">
            {firstName || lastName
              ? (firstName ? firstName + ' ' : '') +
                (lastName ? lastName + ' ' : '')
              : 'Add name'}{' '}
          </p>

          <div className="mt-4 text-p3 text-primary/50">Contact</div>
          <p className="mt-1">{phone ?? 'Add mobile'}</p>

          <div className="mt-4 text-p3 text-primary/50">Email address</div>
          <p className="mt-1">{email}</p>

          <div className="mt-4 text-p3 text-primary/50">Password</div>
          <p className="mt-1">**************</p>
        </div>
      </div>
    </>
  );
}
