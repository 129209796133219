import {SanitySectionSharedSections} from '~/types';
import {Sections} from '~/components';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function SharedSections({
  section,
}: {
  section: SerializeFrom<SanitySectionSharedSections>;
}) {
  const {sections} = section;

  if (!sections?.length) return null;

  return <Sections sections={sections} />;
}
