import {Disclosure} from '@headlessui/react';
import {IconCaret} from '~/components';
export function Accordion({
  title,
  children,
  variant = 'large',
}: {
  title: string;
  children: React.ReactNode;
  variant?: 'large' | 'small';
}) {
  const variants = {
    large: 'text-h5',
    small: 'text-h3',
  };

  const padding = {
    large: 'py-6',
    small: 'py-4',
  };

  return (
    <Disclosure
      key={title}
      as="div"
      className="w-full border-b border-white/50"
    >
      {({open}) => (
        <>
          <Disclosure.Button
            className={`flex w-full justify-between ${padding[variant]}`}
          >
            <h3 className={`text-left uppercase ${variants[variant]}`}>
              {title}
            </h3>
            <IconCaret
              direction={open ? 'up' : 'down'}
              className="flex-shrink-0"
            />
          </Disclosure.Button>

          <Disclosure.Panel className={'grid gap-2 pb-6'}>
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
