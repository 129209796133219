export function HeroCaption({
  as: Component = 'h3',
  className,
  children,
  ...props
}: {
  as?: React.ElementType;
  className?: string;
  children: React.ReactNode;
  [key: string]: any;
}) {
  return (
    <Component {...props} className={className}>
      {children}
    </Component>
  );
}
