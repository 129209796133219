import {
  PortableText as PortableTextComponent,
  PortableTextComponents,
} from '@portabletext/react';
import {useMemo} from 'react';

import type {PortableTextBlock as SanityBlock} from '@sanity/types';
import clsx from 'clsx';
import {SanityShopifyProducts} from '~/types';
import LinkEmailAnnotation from './annotations/LinkEmail';
import LinkExternalAnnotation from './annotations/LinkExternal';
import LinkInternalAnnotation from './annotations/LinkInternal';
import LinkProductAnnotation from './annotations/LinkProduct';
import Lists from './lists/List';
import Blockquote from './blocks/Blockquote';
import BlockCallout from './blocks/Callout';
import ImagesBlock from './blocks/Images';

type Props = {
  value: SanityBlock[];
  shopifyProducts?: SanityShopifyProducts;
  className?: string;
};

export function PortableText({value, shopifyProducts, className}: Props) {
  // sanityProducts is an array of products with _ids or a single product with an _id
  // const products = getRelevantShopifyProducts(sanityProducts, shopifyProducts);
  const portableTextComponents: PortableTextComponents = useMemo(() => {
    return {
      list: {
        bullet({children}) {
          return <Lists type="bullet">{children}</Lists>;
        },
        number({children}) {
          return <Lists type="number">{children}</Lists>;
        },
      },

      block: {
        lead: ({children}) => <p className="text-p8">{children}</p>,
        blockquote: ({children}) => {
          return <Blockquote>{children}</Blockquote>;
        },
      },
      marks: {
        LinkEmail: LinkEmailAnnotation,
        LinkInternal: LinkInternalAnnotation,
        LinkExternal: LinkExternalAnnotation,
        LinkProduct: (props: any) => (
          <LinkProductAnnotation shopifyProducts={shopifyProducts} {...props} />
        ),
      },

      types: {
        blockCallout: BlockCallout,
        blockImages: (props: any) => (
          <ImagesBlock shopifyProducts={shopifyProducts} {...props} />
        ),
        blockProduct: () => null,
      },
    };
  }, [shopifyProducts]);

  return (
    <div className={clsx(className)}>
      <PortableTextComponent
        value={value}
        components={portableTextComponents}
      />
    </div>
  );
}
