import {Heading, Link, SanityImage} from '~/components';
import {SanityCollection} from '~/types';
import {Image} from '@shopify/hydrogen';

export function CollectionCard({
  collection,
  loading,
}: {
  collection: SanityCollection;
  loading?: HTMLImageElement['loading'];
}) {
  const {image, handle, title, media} = collection;

  return (
    <article className="max-w-xl">
      <Link to={`/collections/${handle}`} className="group grid gap-5">
        <div className="card-image group aspect-[4/5] overflow-clip">
          {media?.featuredImage && (
            <SanityImage
              className="fadeIn"
              crop={media?.featuredImage?.crop}
              hotspot={media?.featuredImage?.hotspot}
              width={440}
              height={550}
              objectFit="contain "
              layout="fill"
              sizes={[880, 1320]}
              src={media?.featuredImage?.asset?._ref}
            />
          )}

          {media?.secondaryImage && (
            <div className="absolute left-0 top-0 aspect-[4/5] w-full opacity-0 transition-opacity group-hover:opacity-100">
              <SanityImage
                className="fadeIn"
                crop={media?.secondaryImage?.crop}
                hotspot={media?.secondaryImage?.hotspot}
                width={440}
                height={550}
                objectFit="contain"
                layout="fill"
                sizes={[880, 1320]}
                src={media?.secondaryImage?.asset?._ref}
              />
            </div>
          )}

          {!media && image && (
            <Image
              className="fadeIn aspect-[4/5] w-full object-contain"
              sizes="450px"
              loaderOptions={{
                crop: 'center',
                scale: 2,
                width: 440,
                height: 550,
              }}
              widths={[880, 1320]}
              data={{url: image}}
              alt={title}
              loading={loading}
            />
          )}
        </div>
        <header className="flex flex-col items-start gap-2">
          <h2 className="text-h4 lg:text-h5">{title}</h2>
          <p className="underline-hover text-p3 leading-none">Shop Now</p>
        </header>
      </Link>
    </article>
  );
}
