import {
  Button,
  Labels,
  PortableText,
  SanityImage,
  SanityMuxVideo,
} from '~/components';
import {SanitySectionCustomMediaText} from '~/types';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';
import {useState} from 'react';

export function CustomMediaText({
  section,
}: {
  section: SerializeFrom<SanitySectionCustomMediaText>;
}) {
  const {
    contentGroup: {
      actions,
      carBrand,
      contentType,
      labels,
      preTitle,
      title,
      description,
    },
    image,
    switchOrder,
    display,
    video,
  } = section;

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  return (
    <section
      id={carBrand?.title}
      className="relative flex w-full flex-col justify-end bg-dark"
    >
      <div className="z-10">
        <div className="mx-auto">
          <div className="flex flex-col items-center md:grid md:grid-cols-2">
            <div
              className={clsx(
                'relative aspect-[5/4] h-full w-full',
                switchOrder ? 'md:order-1' : '',
              )}
            >
              {display == 'video' ? (
                <SanityMuxVideo
                  video={video}
                  isPlaying={isPlaying}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  setMediaActive={setIsPlaying}
                />
              ) : (
                <SanityImage
                  className="fadeIn"
                  crop={image?.crop}
                  hotspot={image?.hotspot}
                  width={1250}
                  objectFit="cover"
                  layout="fill"
                  sizes={[937, 1250, 1875]}
                  src={image?.asset?._ref}
                />
              )}
            </div>

            <div
              className={clsx(
                'section-px flex max-w-[56ch]  flex-col space-y-8 py-10 md:space-y-10 md:justify-self-center md:p-10',
                contentType == 'image' && 'items-center  sm:w-[30ch]',
              )}
            >
              {contentType == 'text' ? (
                <>
                  {labels && <Labels labels={labels} className="flex gap-2" />}
                  {preTitle && (
                    <p className="text-h4 uppercase text-white">{preTitle}</p>
                  )}
                  {title && (
                    <h2 className="max-w-prose text-d4 uppercase md:text-d8">
                      {title}
                    </h2>
                  )}
                  {description && (
                    <PortableText
                      value={description}
                      className="portable-text"
                    />
                  )}
                </>
              ) : (
                <>
                  {display == 'image' && (
                    <SanityImage
                      className={clsx(
                        'fadeIn backface-hidden h-40 max-w-[80%] transition-transform duration-500 sm:max-w-full',
                      )}
                      alt={carBrand.title}
                      src={carBrand?.logo?.asset?._ref}
                    />
                  )}
                </>
              )}

              {actions && (
                <div className="flex flex-col gap-3 xs:flex-row">
                  {actions.map((action) => (
                    <Button
                      key={action.link?._key}
                      to={action.link?.to}
                      type={action.link?._type}
                      variant={action.variant}
                      width="auto"
                    >
                      {action?.link?.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
