import {useLocation, useMatches} from '@remix-run/react';
import type {
  MenuItem,
  Menu,
  MoneyV2,
  ProductVariant,
  Metafield,
} from '@shopify/hydrogen/storefront-api-types';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import {HeaderTheme, SanityShopifyProducts} from '~/types';

import {countries} from '~/data/countries';
import {I18nLocale} from '~/types';
import {SanityProduct} from '~/types';

export interface EnhancedMenuItem extends MenuItem {
  to: string;
  target: string;
  isExternal?: boolean;
  items: EnhancedMenuItem[];
}

export interface EnhancedMenu extends Menu {
  items: EnhancedMenuItem[];
}

export function missingClass(string?: string, prefix?: string) {
  if (!string) {
    return true;
  }

  const regex = new RegExp(` ?${prefix}`, 'g');
  return string.match(regex) === null;
}

export function searchParamAddOrRemove(
  params: URLSearchParams,
  key: string,
  value: string,
  method: 'remove' | 'append' | 'set',
) {
  switch (method) {
    case 'remove':
      return URLSearchParamsRemove(params, key, value);
    case 'append':
      return params.append(key, value);
    case 'set':
      return params.set(key, value);
    default:
      return params;
  }
}

export function URLSearchParamsRemove(
  params: URLSearchParams,
  key: string,
  value: string,
) {
  const entries = params.getAll(key);
  const newEntries = entries.filter((entry) => entry !== value);
  params.delete(key);
  newEntries.forEach((newEntry) => params.append(key, newEntry));
}

export function getExcerpt(text: string) {
  const regex = /<p.*>(.*?)<\/p>/;
  const match = regex.exec(text);
  return match?.length ? match[0] : text;
}

export function isNewArrival(date: string, daysOld = 5) {
  return false;
  return (
    new Date(date).valueOf() >
    new Date().setDate(new Date().getDate() - daysOld).valueOf()
  );
}

export function isDiscounted(price: MoneyV2, compareAtPrice: MoneyV2) {
  if (compareAtPrice?.amount > price?.amount) {
    const percentageOff = Math.round(
      ((parseFloat(compareAtPrice.amount) - parseFloat(price.amount)) /
        parseFloat(compareAtPrice.amount)) *
        100,
    );
    return percentageOff;
  }
  return false;
}

function resolveToFromType(
  {
    customPrefixes,
    pathname,
    type,
  }: {
    customPrefixes: Record<string, string>;
    pathname?: string;
    type?: string;
  } = {
    customPrefixes: {},
  },
) {
  if (!pathname || !type) return '';

  /*
    MenuItemType enum
    @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType
  */
  const defaultPrefixes = {
    BLOG: 'blogs',
    COLLECTION: 'collections',
    COLLECTIONS: 'collections', // Collections All (not documented)
    FRONTPAGE: 'frontpage',
    HTTP: '',
    PAGE: 'pages',
    CATALOG: 'collections/all', // Products All
    PRODUCT: 'products',
    SEARCH: 'search',
    SHOP_POLICY: 'policies',
  };

  const pathParts = pathname.split('/');
  const handle = pathParts.pop() || '';
  const routePrefix: Record<string, string> = {
    ...defaultPrefixes,
    ...customPrefixes,
  };

  switch (true) {
    // special cases
    case type === 'FRONTPAGE':
      return '/';

    case type === 'ARTICLE': {
      const blogHandle = pathParts.pop();
      return routePrefix.BLOG
        ? `/${routePrefix.BLOG}/${blogHandle}/${handle}/`
        : `/${blogHandle}/${handle}/`;
    }

    case type === 'COLLECTIONS':
      return `/${routePrefix.COLLECTIONS}`;

    case type === 'SEARCH':
      return `/${routePrefix.SEARCH}`;

    case type === 'CATALOG':
      return `/${routePrefix.CATALOG}`;

    // common cases: BLOG, PAGE, COLLECTION, PRODUCT, SHOP_POLICY, HTTP
    default:
      return routePrefix[type]
        ? `/${routePrefix[type]}/${handle}`
        : `/${handle}`;
  }
}

/*
  Parse each menu link and adding, isExternal, to and target
*/
function parseItem(customPrefixes = {}) {
  return function (item: MenuItem): EnhancedMenuItem {
    if (!item?.url || !item?.type) {
      // eslint-disable-next-line no-console
      console.warn('Invalid menu item.  Must include a url and type.');
      // @ts-ignore
      return;
    }

    // extract path from url because we don't need the origin on internal to attributes
    const {pathname} = new URL(item.url);

    /*
      Currently the MenuAPI only returns online store urls e.g — xyz.myshopify.com/..
      Note: update logic when API is updated to include the active qualified domain
    */
    const isInternalLink = /\.myshopify\.com/g.test(item.url);

    const parsedItem = isInternalLink
      ? // internal links
        {
          ...item,
          isExternal: false,
          target: '_self',
          to: resolveToFromType({type: item.type, customPrefixes, pathname}),
        }
      : // external links
        {
          ...item,
          isExternal: true,
          target: '_blank',
          to: item.url,
        };

    return {
      ...parsedItem,
      items: item.items?.map(parseItem(customPrefixes)),
    };
  };
}

/*
  Recursively adds `to` and `target` attributes to links based on their url
  and resource type.
  It optionally overwrites url paths based on item.type
*/
export function parseMenu(menu: Menu, customPrefixes = {}): EnhancedMenu {
  if (!menu?.items) {
    // eslint-disable-next-line no-console
    console.warn('Invalid menu passed to parseMenu');
    // @ts-ignore
    return menu;
  }

  return {
    ...menu,
    items: menu.items.map(parseItem(customPrefixes)),
  };
}

export const getHeaderTheme = (pathname: string, override?: HeaderTheme) => {
  if (override) {
    return override;
  }

  const isHome = pathname === '/';
  const isAbout = pathname.includes('/about');

  if (isHome || isAbout) {
    return 'transparent';
  }

  return 'dark';
};

export const INPUT_STYLE_CLASSES =
  'bg-black/10 w-full border transition border-spanishGray appearance-none p-3 px-6 focus:ring-transparent focus:border-white placeholder:text-spanishGray text-p4 rounded-md';

export const getInputStyleClasses = (isError?: string | null) => {
  return `${INPUT_STYLE_CLASSES} ${
    isError ? 'border-red-500' : 'border-primary/20'
  }`;
};

export function statusMessage(status: string) {
  const translations: Record<string, string> = {
    ATTEMPTED_DELIVERY: 'Attempted delivery',
    CANCELED: 'Canceled',
    CONFIRMED: 'Confirmed',
    DELIVERED: 'Delivered',
    FAILURE: 'Failure',
    FULFILLED: 'Fulfilled',
    IN_PROGRESS: 'In Progress',
    IN_TRANSIT: 'In transit',
    LABEL_PRINTED: 'Label printed',
    LABEL_PURCHASED: 'Label purchased',
    LABEL_VOIDED: 'Label voided',
    MARKED_AS_FULFILLED: 'Marked as fulfilled',
    NOT_DELIVERED: 'Not delivered',
    ON_HOLD: 'On Hold',
    OPEN: 'Open',
    OUT_FOR_DELIVERY: 'Out for delivery',
    PARTIALLY_FULFILLED: 'Partially Fulfilled',
    PENDING_FULFILLMENT: 'Pending',
    PICKED_UP: 'Displayed as Picked up',
    READY_FOR_PICKUP: 'Ready for pickup',
    RESTOCKED: 'Restocked',
    SCHEDULED: 'Scheduled',
    SUBMITTED: 'Submitted',
    UNFULFILLED: 'Unfulfilled',
  };
  try {
    return translations?.[status];
  } catch (error) {
    return status;
  }
}

export function pathnameToTitle(pathname: string) {
  // Remove leading slash and split pathname into parts
  const parts = pathname.substring(1).split('/');

  // Capitalize each part and join with a space
  const title = parts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');

  return title;
}

export function snakeToCamel(s: string): string {
  const words = s.split('_');
  return (
    words[0] +
    words
      .slice(1)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('')
  );
}
export function formatMetafields(
  metafields?: (SerializeFrom<Metafield> | null)[],
): Record<string, any> {
  const formattedMetafields: Record<string, any> = {};

  if (!metafields) {
    return formattedMetafields;
  }

  for (const metafield of metafields) {
    if (!metafield) {
      continue;
    }
    const parsedKey = snakeToCamel(metafield.key);
    try {
      const parsedValue = JSON.parse(metafield.value ?? '');

      // loop through the parsed value and convert any nested keys to camel case
      if (typeof parsedValue === 'object') {
        for (const key in parsedValue) {
          const camelKey = snakeToCamel(key);
          if (camelKey !== key) {
            parsedValue[camelKey] = parsedValue[key];
            delete parsedValue[key];
          }
        }
      }
      formattedMetafields[parsedKey] = parsedValue;
    } catch {
      formattedMetafields[parsedKey] = metafield.value;
    }
  }

  return formattedMetafields;
}

export function getOffsetFromTimezone(timeZone: string) {
  const offset = new Intl.DateTimeFormat('en', {
    timeZone,
    timeZoneName: 'shortOffset',
  })
    ?.formatToParts()
    ?.find((part) => part.type === 'timeZoneName')?.value;

  return offset;
}

/**
 * Errors can exist in an errors object, or nested in a data field.
 */
export function assertApiErrors(data: Record<string, any> | null | undefined) {
  const errorMessage = data?.customerUserErrors?.[0]?.message;
  if (errorMessage) {
    throw new Error(errorMessage);
  }
}

export function getIdfromGID(gid?: string) {
  if (!gid) return null;
  return gid.split('/').pop();
}

export const DEFAULT_LOCALE: I18nLocale = Object.freeze({
  ...countries.default,
  pathPrefix: '',
});

export function getLocaleFromRequest(request: Request): I18nLocale {
  const url = new URL(request.url);
  const firstPathPart =
    '/' + url.pathname.substring(1).split('/')[0].toLowerCase();

  return countries[firstPathPart]
    ? {
        ...countries[firstPathPart],
        pathPrefix: firstPathPart,
      }
    : {
        ...countries['default'],
        pathPrefix: '',
      };
}

// can pass sanityProducts as an array or a single product
// only data needed from sanity is product._id
export function getRelevantShopifyProducts(
  sanityProducts:
    | Array<SerializeFrom<SanityProduct>>
    | SerializeFrom<SanityProduct>,
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>,
) {
  if (!(shopifyProducts && Object.keys(shopifyProducts)?.length)) return [];

  if (Array.isArray(sanityProducts)) {
    return sanityProducts.map((sanityProduct) => ({
      ...shopifyProducts?.[sanityProduct?._id],
    }));
  }

  return [
    {
      ...shopifyProducts?.[sanityProducts?._id],
    },
  ];
}

export function usePrefixPathWithLocale(path: string) {
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;

  return `${selectedLocale.pathPrefix}${
    path.startsWith('/') ? path : '/' + path
  }`;
}

export function useIsHomePath() {
  const {pathname} = useLocation();
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;
  const strippedPathname = pathname.replace(selectedLocale.pathPrefix, '');
  return strippedPathname === '/';
}

/**
 * Checks if a option value e.g. size L has no possible variations in stock
 * @param variants
 * @param optionName e.g. size
 * @param optionValue e.g. L
 */
export function isOptionOutOfStock(
  variants: ProductVariant[],
  optionName: string,
  optionValue: string,
) {
  const filteredVariants = variants.filter((variant) =>
    variant.selectedOptions.some(
      (selectedOption) =>
        selectedOption.name === optionName &&
        selectedOption.value === optionValue,
    ),
  );
  return filteredVariants.every((variant) => !variant.availableForSale);
}

export function getVariantUrl(handle: string, variant: ProductVariant) {
  const variantParams = new URLSearchParams();
  variant?.selectedOptions.forEach(({name, value}) => {
    variantParams.append(name, value);
  });
  return `/products/${handle}?${variantParams.toString()}`;
}

export function getOrderTitle(title: string) {
  const firstChar = title.charAt(0);
  const article = ['a', 'e', 'i', 'o', 'u'].includes(firstChar.toLowerCase())
    ? 'an'
    : 'a';
  const orderTitle = `Order ${article} ${title}`;
  return orderTitle;
}

export function disableDocumentScroll() {
  const scrollBarWidth = window.innerWidth - document.body.clientWidth;
  document.documentElement.style.overflow = 'hidden';
  if (scrollBarWidth)
    document.documentElement.style.paddingRight = `${scrollBarWidth}px`;
}

export function enableDocumentScroll() {
  document.documentElement.style.overflow = '';
  document.documentElement.style.paddingRight = '';
}

/**
 * Validates that a url is local
 * @param url
 * @returns `true` if local `false`if external domain
 */
export function isLocalPath(url: string) {
  try {
    // We don't want to redirect cross domain,
    // doing so could create fishing vulnerability
    // If `new URL()` succeeds, it's a fully qualified
    // url which is cross domain. If it fails, it's just
    // a path, which will be the current domain.
    new URL(url);
  } catch (e) {
    return true;
  }

  return false;
}
