import {useFetcher} from '@remix-run/react';
import {Button, Label} from '~/components';
import {RadioGroup} from '@headlessui/react';
import {getInputStyleClasses, getOffsetFromTimezone} from '~/lib/utils';
import {getCountryForTimezone} from 'countries-and-timezones';
import {useState} from 'react';
import DatePicker from 'react-datepicker';

const contactMethods = [
  {
    value: 'call',
    label:
      'I would like to schedule a call with a personal Senturion concierge',
  },
  {
    value: 'visit',
    label:
      'I would like to book an appointment to visit the Senturion London showroom',
  },
];

export function Enquire() {
  const fetcher = useFetcher();
  const currenTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [timezone, setTimezone] = useState<string>(currenTimeZone);
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState<string | undefined>();

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const countriesFromTimezone = getCountryForTimezone(timezone) || {
    name: 'United Kingdom',
  };
  const country = countriesFromTimezone.name;
  return (
    <fetcher.Form className="w-full" method="post" action="/api/klaviyo">
      <input type="hidden" name="event" value="Enquiry Form" />
      <div className="flex flex-col gap-7">
        <div className="grid grid-cols-2 gap-7">
          {/* Contact Method */}
          <RadioGroup
            name="contactMethod"
            className="col-span-2 grid grid-cols-2 gap-7"
            defaultValue={contactMethods[0].value}
          >
            {contactMethods.map((plan) => (
              <RadioGroup.Option
                key={plan.value}
                value={plan.value}
                className={({checked}) => `
            ${
              checked
                ? ' border-white bg-white text-frost'
                : 'cursor-pointer border-spanishGray'
            }
            relative flex select-none rounded-md border p-6 text-center text-h2 uppercase focus:outline-none
          `}
              >
                {plan.label}
              </RadioGroup.Option>
            ))}
          </RadioGroup>

          {/* First Name */}
          <Label>
            First Name
            <input
              className={`my-1 ${getInputStyleClasses()}`}
              name="firstName"
              type="text"
              autoComplete="given-name"
              required
              aria-label="First Name"
            />
          </Label>

          {/* Last Name */}
          <Label>
            Last Name
            <input
              className={`my-1 ${getInputStyleClasses()}`}
              name="lastName"
              type="text"
              autoComplete="family-name"
              required
              aria-label="Last Name"
            />
          </Label>

          {/* Email */}
          <Label>
            Email address
            <input
              className={`my-1 ${getInputStyleClasses()}`}
              name="email"
              type="email"
              autoComplete="email"
              required
              aria-label="Email address"
            />
          </Label>

          {/* Phone */}
          <Label>
            Phone number
            <input
              className={`my-1 ${getInputStyleClasses()}`}
              name="phone"
              type="tel"
              autoComplete="tel"
              required
              aria-label="Phone number"
            />
          </Label>

          {/* Country */}
          <Label>
            Country
            <input
              className={`my-1 ${getInputStyleClasses()}`}
              name="country"
              type="text"
              autoComplete="country"
              required
              aria-label="Country"
              defaultValue={country}
            />
          </Label>

          {/* Timezone select */}
          <Label>
            Timezone
            <select
              className={`my-1 ${getInputStyleClasses()}`}
              name="timezone"
              required
              aria-label="Timezone"
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
            >
              {/* @ts-ignore browser function exists */}
              {Intl.supportedValuesOf('timeZone')?.map((timeZone) => {
                const offset = getOffsetFromTimezone(timeZone);

                return (
                  <option
                    key={timeZone}
                    value={timeZone}
                    className="bg-white py-1 text-black"
                  >
                    {`${timeZone} ${offset}`}
                  </option>
                );
              })}
            </select>
          </Label>

          {/* Date */}
          <div className="align-center col-span-2 flex justify-center md:col-span-1">
            <DatePicker
              selected={date}
              onChange={(d) => setDate(d)}
              required
              inline
            />
            <input
              type="hidden"
              name="date"
              value={date?.toLocaleDateString('en-GB', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            />
          </div>

          {/* Time and selected date time */}
          <div className="col-span-2 flex flex-col md:col-span-1">
            <Label>
              Time
              <input
                className={`my-1 ${getInputStyleClasses()}`}
                name="time"
                type="time"
                required
                aria-label="Time"
                onChange={handleTimeChange}
              />
            </Label>
            {/* Selection */}
            <div className="my-5 mt-auto">
              <Label>You Have Selected:</Label>
              {date && time && timezone ? (
                <div>
                  {/* formatted date and time */}
                  <p className="my-5 text-p7 uppercase">
                    {`${date?.toLocaleDateString('en-GB', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })} ${time}`}
                  </p>

                  <p className="text-p5 uppercase">{`${timezone} ${getOffsetFromTimezone(
                    timezone,
                  )}`}</p>
                </div>
              ) : (
                <div className="h-[142px] pt-3 text-p5">
                  Please select a date and time
                </div>
              )}
            </div>
          </div>

          {/* Supercar details */}
          <Label className="col-span-2">
            Supercar details
            <input
              className={`my-1 ${getInputStyleClasses()}`}
              name="supercarDetails"
              type="text"
              required
              aria-label="Supercar details"
            />
          </Label>

          {/* Other details */}
          <Label className="col-span-2">
            Any other details (e.g. urgency of order, occasion etc.)
            <textarea
              className={`my-1 ${getInputStyleClasses()}`}
              name="otherDetails"
              aria-label="Other details"
            />
          </Label>
        </div>

        {fetcher.data?.formError && (
          <p className="text-center text-p3 text-torchRed">
            {fetcher.data.formError}
          </p>
        )}
        {fetcher.data?.ok && (
          <p className="text-center text-p3 text-white">
            Thank you for your enquiry.
          </p>
        )}
        <Button
          type="submit"
          variant="light"
          disabled={fetcher.state !== 'idle'}
          aria-label="Subscribe Newsletter"
        >
          {fetcher.data?.ok
            ? 'Submitted'
            : fetcher.state !== 'idle'
            ? 'Subitting..'
            : 'Submit'}
        </Button>
      </div>
    </fetcher.Form>
  );
}
