// prettier-ignore
import type { PortableTextBlock, PortableTextMarkDefinition } from '@portabletext/types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';

import clsx from 'clsx';
import {SanityLinkExternal} from '~/types';

type Props = PortableTextBlock & {
  value: PortableTextMarkDefinition & SanityLinkExternal;
  children: React.ReactNode;
};

const LinkExternalAnnotation = ({children, value}: Props) => {
  return (
    <a
      className={clsx(
        'inline-flex items-center transition-opacity duration-200',
        'hover:opacity-60',
      )}
      href={value?.to}
      rel="noopener noreferrer"
      target={value?.newWindow ? '_blank' : '_self'}
    >
      <>{children}</>
      <FontAwesomeIcon icon={faExternalLink} className="ml-1" size="xs" />
    </a>
  );
};

export default LinkExternalAnnotation;
