import {PortableText, SanityImage} from '~/components';
import {SanitySectionProductFeatures} from '~/types';
import type {SerializeFrom} from '@shopify/remix-oxygen';

export function ProductFeatures({
  section,
}: {
  section: SerializeFrom<SanitySectionProductFeatures>;
}) {
  const {features} = section;

  return (
    <section>
      <div className="section-px my-15 lg:my-20">
        <div className="mx-auto max-w-site">
          <h2 className="mb-6 text-h5 lg:mb-11 lg:text-h6">Product Features</h2>
          <div className="grid grid-cols-4 gap-8 lg:gap-4">
            {features?.map((feature) => (
              <div
                key={feature._key}
                className="col-span-4 md:col-span-2 lg:col-span-1"
              >
                <div className="flex flex-col gap-6">
                  <SanityImage
                    className="fadeIn aspect-[5/6] rounded"
                    crop={feature?.image?.crop}
                    hotspot={feature?.image?.hotspot}
                    width={428}
                    height={535}
                    objectFit="contain"
                    layout="responsive"
                    sizes={[428]}
                    src={feature?.image?.asset?._ref}
                  />
                  <div>
                    <h3 className="mb-2 text-h4">{feature?.title}</h3>
                    <PortableText
                      value={feature?.description}
                      className="text-p3"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section-px">
        <hr className="mx-auto max-w-site" />
      </div>
    </section>
  );
}
