import React from 'react';
import {SanityImage} from '~/components';
import {SanityCarBrand} from '~/types';

export function CustomCarBrandCard({carBrand}: {carBrand: SanityCarBrand}) {
  const {title, logo} = carBrand;
  return (
    <article className="flex w-1/3 justify-center">
      <a href={`#${title}`} className="">
        {logo && (
          <SanityImage
            className="fadeIn backface-hidden transition-transform duration-500 "
            width={440}
            objectFit="cover"
            alt={title}
            sizes={[]}
            layout="responsive"
            src={logo?.asset?._ref}
          />
        )}
      </a>
    </article>
  );
}
