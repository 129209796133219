import clsx from 'clsx';
import {forwardRef, HTMLProps, ReactNode} from 'react';

export type LabelProps = HTMLProps<HTMLLabelElement> & {
  variant?: 'default';
  className?: string;
  children?: ReactNode;
  [key: string]: any;
};

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({className = '', type, variant = 'default', children, ...props}, ref) => {
    const variants = {
      default: 'text-p2 uppercase',
    };

    const styles = clsx(variants[variant], className);

    return (
      <label {...props} className={styles} ref={ref}>
        {children}
      </label>
    );
  },
);
