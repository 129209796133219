import {useEffect, useState} from 'react';
import {IconClose, Newsletter, SanityImage} from '~/components';
import {CookieData, SanityNewsletterModal} from '~/types';
import {useFetcher} from '@remix-run/react';

export function NewsletterModal(
  props: SanityNewsletterModal & {cookies: CookieData},
) {
  const fetcher = useFetcher();
  const {title, subtitle, image, active, cookies} = props;

  const {isNewsletterModalClosed, isNewsletterSubscribed} = cookies || {};

  const defaultOpen =
    active && !isNewsletterSubscribed && !isNewsletterModalClosed;

  const [modalOpen, setModalOpen] = useState(false);

  //if defaulOpen is true open modal after 15 seconds
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (defaultOpen && !modalOpen) {
      timeout = setTimeout(() => {
        setModalOpen(true);
      }, 15000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [defaultOpen, modalOpen]);

  //if modal is open and user is subscribed close modal after 1 second
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (modalOpen && isNewsletterSubscribed) {
      // close after 1 second
      timeout = setTimeout(() => {
        setModalOpen(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [modalOpen, isNewsletterSubscribed]);

  const handleClose = () => {
    setModalOpen(false);
    // set cookie to not show modal again
    fetcher.submit(
      {closeModal: 'true'},
      {method: 'post', action: '/api/newsletter'},
    );
  };

  if (!modalOpen) return null;

  return (
    <div className="fadeIn fixed inset-0 z-50">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute inset-0 flex items-end justify-end">
        {/* <button className="absolute inset-0" onClick={handleClose} /> */}
        <div className="relative flex w-full max-w-[720px] select-none rounded-t-md bg-dark md:m-10 md:rounded-none">
          <div className="relative hidden w-[240px] md:flex">
            <SanityImage
              crop={image?.crop}
              hotspot={image?.hotspot}
              width={240}
              height={300}
              className="h-full w-full object-cover"
              sizes={[300]}
              src={image?.asset?._ref}
            />
          </div>

          <div className="w-full p-3 py-10 text-white md:p-10">
            <button
              className="absolute right-0 top-0 p-3"
              onClick={handleClose}
            >
              <IconClose className="w-5" />
            </button>
            <h2 className="mb-2 text-h6">{title}</h2>
            <p className="mb-4 text-p3">{subtitle}</p>
            <Newsletter source="modal" />
          </div>
        </div>
      </div>
    </div>
  );
}
