import {Heading, SanityImage} from '~/components';
import {SanitySectionPageHeading} from '~/types';

import type {SerializeFrom} from '@shopify/remix-oxygen';

export function PageHeading({
  section,
}: {
  section: SerializeFrom<SanitySectionPageHeading>;
}) {
  const {title, theme} = section;

  let bgColor = '';
  let textColor = 'text-dark';
  let border = 'border-b';

  if (theme === 'dark') {
    bgColor = 'bg-dark';
    textColor = 'text-silk';
    border = '';
  }

  return (
    <section
      className={`relative flex w-full flex-col justify-end ${bgColor} ${textColor}`}
    >
      <div className="section-px z-10 sm:px-8 md:px-10">
        <div
          className={`relative mx-auto max-w-site border-b pb-10 pt-15 md:pb-15 md:pt-44 ${border}`}
        >
          {title && (
            <Heading size="pageHeading" as="h1">
              {title}
            </Heading>
          )}
        </div>
      </div>
    </section>
  );
}
