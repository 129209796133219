import {useState} from 'react';
import clsx from 'clsx';
import {ProductVariant} from '@shopify/hydrogen/storefront-api-types';
import {Button, Drawer, Label, useDrawer} from '~/components';
import {getInputStyleClasses} from '~/lib/utils';
import {useFetcher} from '@remix-run/react';

export function NotifyStock({variant}: {variant: ProductVariant}) {
  const [nativeEmailError, setNativeEmailError] = useState<null | string>(null);

  const {isOpen, openDrawer, closeDrawer} = useDrawer();

  const fetcher = useFetcher();

  return (
    <>
      <Button width="full" onClick={openDrawer}>
        Notify me when in stock
      </Button>
      <Drawer
        open={isOpen}
        heading="Product Unavailable"
        onClose={closeDrawer}
        openFrom="right"
      >
        <div className="flex h-screen-no-nav flex-col overflow-y-auto px-6 md:px-12">
          <h3 className="mb-2 text-h3">
            We&apos;re sorry the product isn&apos;t available.
          </h3>
          <p className="mb-6 text-p3">
            Submit your email address below and we&apos;ll be in touch as soon
            as it&apos;s back in stock.
          </p>

          <fetcher.Form
            className="flex h-full flex-col"
            action="/api/notify-stock"
            method="post"
          >
            <div className="flex flex-col ">
              <input
                type="hidden"
                name="variantId"
                value={variant?.id?.replace(
                  'gid://shopify/ProductVariant/',
                  '',
                )}
              />
              <div>
                <Label>
                  Email address
                  <input
                    className={`mb-1 ${getInputStyleClasses(nativeEmailError)}`}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    aria-label="Email address"
                    onBlur={(event) => {
                      setNativeEmailError(
                        event.currentTarget.value.length &&
                          !event.currentTarget.validity.valid
                          ? 'Invalid email address'
                          : null,
                      );
                    }}
                  />
                </Label>
                {nativeEmailError && (
                  <p className="text-p2 text-red-500">
                    {nativeEmailError} &nbsp;
                  </p>
                )}
              </div>
              {fetcher.data && (
                <p
                  className={clsx(
                    'mt-2 text-p4',
                    fetcher.data?.success ? 'text-dark' : 'text-red-500',
                  )}
                >
                  {fetcher.data.message}
                </p>
              )}
            </div>
            <div className="mt-auto border-t py-4 md:py-8">
              <Button
                type="submit"
                disabled={fetcher.state !== 'idle'}
                width="full"
                variant="dark"
              >
                Submit
              </Button>
            </div>
          </fetcher.Form>
        </div>
      </Drawer>
    </>
  );
}
