import clsx from 'clsx';
import {CSSProperties} from 'react';

// TODO: move this to a config file or sanity
//hex code config for colors
const colorHexCodes: {[key: string]: string} = {
  brown: '#713C2A',
  white: '#ffffff',
  green: '#50685A',
  black: '#1D1D1B',
  navy: '#1A2C38',
  sand: '#C19B63',
  grey: '#969B9E',
  ivory: '#F5F5E6',
};

export function ColorOption({
  size = 'small',
  value,
  isActive,
  isSoldOut,
  className,
  ...props
}: {
  size?: 'small' | 'large';
  value: string;
  isActive?: boolean;
  isSoldOut?: boolean;
  className?: string;
  [key: string]: any;
}) {
  return (
    <span
      {...props}
      className={clsx(className, 'flex rounded-full border p-[1px]', {
        'border-black': isActive,
        'border-transparent': !isActive,
        // 'opacity-80': isSoldOut,
      })}
    >
      <span
        style={
          {backgroundColor: colorHexCodes[value.toLowerCase()]} as CSSProperties
        }
        className={clsx('flex items-center justify-center rounded-full', {
          'h-2 w-2': size === 'small',
          'h-6 w-6': size === 'large',
        })}
      ></span>
    </span>
  );
}
