import {useSearchParams} from '@remix-run/react';
import {flattenConnection, Image, Money, useMoney} from '@shopify/hydrogen';
import {
  Image as ImageType,
  MoneyV2,
  Product,
  ProductVariant,
} from '@shopify/hydrogen/storefront-api-types';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';
import {useEffect, useState} from 'react';
import {Heading, Link, SanityImage} from '~/components';
import {getProductPlaceholder} from '~/lib/placeholders';
import {isDiscounted} from '~/lib/utils';
import {SanityAssetImage} from '~/types';

export function ProductTile({
  product,
  className,
  loading,
  onClick,
  sanityImage,
}: {
  product: SerializeFrom<Product>;
  className?: string;
  loading?: HTMLImageElement['loading'];
  onClick?: () => void;
  quickAdd?: boolean;
  sanityImage: SanityAssetImage;
}) {
  const cardProduct: Product = product?.variants
    ? (product as Product)
    : getProductPlaceholder();

  const variants = flattenConnection(cardProduct.variants) || [];

  // if query params for color are present, we use that to select the variant
  const [params] = useSearchParams();
  const paramVariantOptions = params.getAll('variantOption');
  const colorParams = paramVariantOptions?.filter((option) =>
    option.startsWith('color'),
  );
  const firstVariant = variants[0];
  const variantFromParam =
    colorParams?.[0] &&
    variants.find(
      (variant) =>
        variant.selectedOptions.find((option) => option.name === 'Color')
          ?.value === colorParams[0].split(':')[1],
    );
  const initialvariant = variantFromParam ? variantFromParam : firstVariant;

  const [selectedVariant, setSelectedVariant] =
    useState<ProductVariant>(initialvariant);

  // check if params have changed and update selected variant
  useEffect(() => {
    if (initialvariant.id !== selectedVariant.id) {
      setSelectedVariant(initialvariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialvariant]);

  if (!initialvariant) return null;

  const {image, price, compareAtPrice, selectedOptions} = selectedVariant;

  const firstMedia = cardProduct.media
    ? flattenConnection(cardProduct.media)[0]
    : undefined;
  const hoverImage: ImageType | undefined =
    //@ts-ignore - type is being checked by mediaContentType
    firstMedia?.mediaContentType === 'IMAGE' ? firstMedia?.image : undefined;

  const colorOptions = cardProduct.options?.find(
    (option) => option.name === 'Color',
  );

  // If the selected variant is the first variant, we don't need to add the query params
  const variantParams =
    selectedVariant.id === firstVariant.id
      ? ''
      : `?Color=${
          selectedOptions.find((option) => option.name === 'Color')?.value
        }`;

  return (
    <div className="fadeIn rounded-md border border-lightGray/60 bg-offWhite">
      <Link
        onClick={onClick}
        to={`/products/${product.handle}${variantParams}`}
        prefetch="intent"
      >
        <div className={clsx('flex', className)}>
          <div className="card-image group relative aspect-[5/6] max-w-[60px] ">
            {sanityImage && (
              <SanityImage
                crop={sanityImage?.crop}
                width={60}
                height={75}
                objectFit="contain"
                className="w-full"
                hotspot={sanityImage?.hotspot}
                layout="responsive"
                sizes={[60]}
                src={sanityImage?.asset?._ref}
              />
            )}
            {!sanityImage && image && (
              <Image
                className="w-full"
                loaderOptions={{
                  crop: 'center',
                  width: '60',
                  height: '75',
                }}
                width={60}
                height={75}
                data={image}
                alt={image.altText || `Picture of ${product.title}`}
                loading={loading}
              />
            )}
          </div>
          <div className="flex flex-col justify-center gap-1 p-4">
            <Heading
              as="h3"
              size={'prodCard'}
              className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-h3"
            >
              {product.title}
            </Heading>
            <div className="flex gap-2 text-p3">
              {isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2) && (
                <CompareAtPrice
                  className={'text-web'}
                  data={compareAtPrice as MoneyV2}
                />
              )}
              <Money withoutTrailingZeros data={price!} />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

function CompareAtPrice({
  data,
  className,
}: {
  data: MoneyV2;
  className?: string;
}) {
  const {currencyNarrowSymbol, withoutTrailingZerosAndCurrency} =
    useMoney(data);

  const styles = clsx('strike', className);

  return (
    <span className={styles}>
      {currencyNarrowSymbol}
      {withoutTrailingZerosAndCurrency}
    </span>
  );
}
