import {useState} from 'react';
import type {
  SanityAssetImage,
  SanityImageWithProductHotspots,
  SanityShopifyProducts,
} from '~/types';
import {ProductHotspot, ButtonIcon} from '~/components';
import {SanityImage} from '~/components';
import {getRelevantShopifyProducts} from '~/lib/utils';
import {faBagShopping} from '@fortawesome/pro-regular-svg-icons';
import {SerializeFrom} from '@shopify/remix-oxygen';

type Props = {
  content: SanityImageWithProductHotspots;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
};

export function ImageWithProductHotspots({content, shopifyProducts}: Props) {
  const [showHotspots, setShowHotspots] = useState(false);

  const products = getRelevantShopifyProducts(
    // @ts-expect-error TODO: fix this BEN!
    content.productHotspots,
    shopifyProducts,
  );

  return (
    <div className="relative flex h-full w-full">
      <div className="absolute inset-0 h-full w-full overflow-clip">
        {content.productHotspots?.map((hotspot, index) => (
          <ProductHotspot
            // @ts-expect-error TODO: fix this BEN!
            shopifyProduct={products[index]}
            varaintGid={hotspot?.variantGid}
            key={hotspot._key}
            x={hotspot.x}
            y={hotspot.y}
            // @ts-expect-error TODO: fix this BEN!
            sanityImage={
              hotspot.galleries?.find(
                (gallery) => gallery.color === hotspot.selectedColor,
              )?.media
            }
            showHotspots={showHotspots}
          />
        ))}

        <div className="absolute bottom-10 right-10 z-10">
          <ButtonIcon
            as="button"
            variant={'light'}
            width="auto"
            onClick={() => setShowHotspots(!showHotspots)}
            icon={faBagShopping}
          >
            Products
          </ButtonIcon>
        </div>

        <SanityImage
          crop={content?.image.crop}
          hotspot={content?.image.hotspot}
          width={1000}
          height={1000}
          objectFit="cover"
          layout="fill"
          sizes={[600, 1000]}
          src={content?.image.asset?._ref}
        />
      </div>
    </div>
  );
}
