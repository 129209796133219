import type {PortableTextBlock} from '@portabletext/types';
import clsx from 'clsx';
import type {SanityModuleImages} from '~/types';
import {ModularImage} from '~/components';

type Props = {
  centered?: boolean;
  value: PortableTextBlock & SanityModuleImages;
};

export default function ImagesBlock({value}: Props) {
  const multipleImages = value.modules.length > 1;
  let alignClass;
  switch (value?.verticalAlign) {
    case 'bottom':
      alignClass = 'items-end';
      break;
    case 'center':
      alignClass = 'items-center';
      break;
    case 'top':
      alignClass = 'items-start';
      break;
  }

  return (
    <div
      className={clsx(
        'not-prose first:mt-0 last:mb-0', //
        value.width === 'full' && 'mx-0 my-0',
        value.width !== 'full' && 'section-px my-15 md:my-40',
      )}
    >
      <div
        className={clsx(
          'mx-auto grid w-full gap-4 md:gap-8',
          value.width === 'full' && !multipleImages && 'max-w-none',
          value.width === 'wide' && 'max-w-site',
          value.width === 'content' && 'max-w-siteHalf',
          multipleImages ? 'grid-cols-2' : 'grid-cols-1',
          alignClass,
        )}
      >
        {value?.modules?.map((module) => (
          <ModularImage
            key={module._key}
            module={module}
            width={value?.width}
            count={value?.count}
          />
        ))}
      </div>
    </div>
  );
}
