import {forwardRef} from 'react';
import {Link} from '~/components';
import clsx from 'clsx';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SanityLink} from '~/types';
import {missingClass} from '~/lib/utils';

export const Button = forwardRef(
  (
    {
      as = 'button',
      className = '',
      variant = 'outline',
      width = 'auto',
      icon,
      to,
      children,
      ...props
    }: {
      as?: React.ElementType;
      className?: string;
      variant?: 'light' | 'dark' | 'gray' | 'inline' | 'outline' | 'camo';
      width?: 'auto' | 'full';
      icon?: any;
      to: SanityLink;
      children: React.ReactNode;
      [key: string]: any;
    },
    ref,
  ) => {
    const Component = to ? Link : as;

    const baseButtonClasses =
      'flex justify-center gap-2 items-center rounded-md uppercase text-button text-center py-3 px-6  xs:py-4 xs:px-8 hover:opacity-80 transition-opacity disabled:text-opacity-50 group whitespace-nowrap';

    const variants = {
      light: `${baseButtonClasses} bg-white text-dark`,
      dark: `${baseButtonClasses} border border-dark/10 bg-dark text-silk`,
      gray: `${baseButtonClasses} bg-jet text-silk`,
      camo: `${baseButtonClasses} bg-camo text-silk`,
      outline: `${baseButtonClasses} border border-white bg-transparent text-white`,
      red: `${baseButtonClasses} border border-red-500 bg-transparent text-red-500`,
      inline: 'leading-none',
    };

    const widths = {
      auto: 'w-auto',
      full: 'w-full',
    };

    const styles = clsx(
      missingClass(className, 'bg-') && variants[variant],
      missingClass(className, 'w-') && widths[width],
      className,
    );

    return (
      <Component className={styles} ref={ref} to={to} {...props}>
        <span>{children}</span>
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="transition-transform group-hover:translate-x-1"
          />
        )}
      </Component>
    );
  },
);
