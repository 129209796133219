import {useRef, useMemo} from 'react';
import {
  HeroCaption,
  SanityImage,
  SanityMuxVideo,
  Logo,
  Brandmark,
  Heading,
} from '~/components';
import {SanityJournalHero} from '~/types';

import {useLocation} from '@remix-run/react';
import {getHeaderTheme} from '~/lib/utils';

import type {SerializeFrom} from '@shopify/remix-oxygen';
import clsx from 'clsx';

export function JournalHero({
  hero,
  date,
}: {
  hero: SerializeFrom<SanityJournalHero>;
  date: string;
}) {
  const location = useLocation();
  const pathname = location.pathname ?? '/';
  const headerTheme = getHeaderTheme(pathname);

  const {image, display, video, overlay, text} = hero;

  const media = useMemo(() => {
    switch (display) {
      case 'image':
        return (
          <SanityImage
            crop={image?.crop}
            hotspot={image?.hotspot}
            width={1920}
            height={1080}
            objectFit="cover"
            layout="fill"
            sizes={[1000, 2000]}
            src={image?.asset?._ref}
          />
        );

      case 'video': {
        return <SanityMuxVideo video={video} />;
      }
    }
  }, [image, video, display]);

  const nexSection = useRef<HTMLDivElement>(null);

  return (
    <section
      className={clsx(
        'relative flex w-full flex-col justify-end',
        headerTheme !== 'transparent' ? 'h-screen-nav' : 'h-screen',
      )}
    >
      <div className="absolute inset-0 overflow-clip">{media}</div>
      <div className="section-px relative z-10 flex h-screen flex-col items-center justify-between py-10">
        <div className="block"></div>
        <div className="h-100 mx-auto w-full max-w-site">
          <div className="m-auto flex w-full flex-col items-center text-white">
            {overlay &&
              (() => {
                switch (overlay) {
                  case 'text':
                    return (
                      <Heading
                        as="h2"
                        size="jumbo"
                        className="max-w-[13ch] text-center text-ivory"
                      >
                        {text}
                      </Heading>
                    );
                  case 'logo':
                    return <Logo className="h-auto w-full max-w-prose-wide" />;
                  case 'brandmark':
                    return (
                      <Brandmark className="h-auto w-full max-w-[16rem]" />
                    );
                }
              })()}
          </div>
        </div>

        <div className="flex w-full justify-between">
          <HeroCaption className="text-white">{date}</HeroCaption>
          {/* scroll down */}
          <button
            className="underline-hover text-p2 text-white sm:text-p3"
            onClick={() =>
              nexSection.current?.scrollIntoView({behavior: 'smooth'})
            }
          >
            Scroll down
          </button>
        </div>
      </div>
      <div ref={nexSection} />
    </section>
  );
}
