import type {SerializeFrom} from '@shopify/remix-oxygen';
import {SanitySection} from '~/types';
import {
  HeroImage,
  Collections,
  Callout,
  Banner,
  Journal,
  Products,
  MediaText,
} from '~/components';
import {SanityShopifyProducts} from '~/types';

type Props = {
  section: SerializeFrom<SanitySection>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
};

export function Modules({
  sections,
  shopifyProducts,
}: {
  sections?: SerializeFrom<SanitySection[]>;
  shopifyProducts?: SerializeFrom<SanityShopifyProducts>;
}) {
  return (
    <div className="w-full">
      {sections?.map((section) => (
        <Section
          key={section._key}
          section={section}
          shopifyProducts={shopifyProducts}
        />
      ))}
    </div>
  );
}

function Section({section, shopifyProducts}: Props) {
  switch (section._type) {
    case 'section.heroImage':
      return <HeroImage section={section} />;
    case 'section.collections':
      return <Collections section={section} />;
    case 'section.callout':
      return <Callout section={section} />;
    case 'section.banner':
      return <Banner section={section} />;
    case 'section.journal':
      return <Journal section={section} />;
    case 'section.products':
      return <Products section={section} shopifyProducts={shopifyProducts} />;
    case 'section.mediaText':
      return <MediaText section={section} />;
    default:
      return null;
  }
}
