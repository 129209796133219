import {faArrowRight, faBadgeCheck} from '@fortawesome/pro-solid-svg-icons';
import {useFetcher} from '@remix-run/react';
import {Button, Input} from '~/components';

export function Newsletter({source}: {source?: string}) {
  const fetcher = useFetcher();

  //TODO: track source in analytics
  return (
    <fetcher.Form className="w-full" method="post" action="/api/newsletter">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col items-center gap-2 xs:flex-row xs:gap-1">
          <div className="relative lg:w-full">
            <Input
              type="email"
              name="email"
              placeholder="Enter your email address"
              disabled={fetcher.state !== 'idle'}
              className="w-full"
              required
            />
          </div>
          <Button
            type="submit"
            disabled={fetcher.state !== 'idle'}
            aria-label="Subscribe Newsletter"
            icon={fetcher.data?.id ? faBadgeCheck : faArrowRight}
          >
            {fetcher.data?.id
              ? 'Subscribed'
              : fetcher.state !== 'idle'
              ? 'Subscribing..'
              : 'Subscribe'}
          </Button>
        </div>
        {fetcher.data?.formError && (
          <p className="text-p1 text-torchRed">{fetcher.data.formError}</p>
        )}
        <p className="text-p1 text-web">
          By subscribing you agree to the Senturion Key Privacy Policy and Terms of
          Serivce apply.
        </p>
      </div>
    </fetcher.Form>
  );
}
