import {ContactDetails} from '~/types';

export function Contact({contact}: {contact?: ContactDetails}) {
  const {address, email, phone} = contact || {};
  return (
    <div className="flex flex-col gap-6">
      {address && (
        <address>
          <strong className="not-italic">{address.label}</strong>
          {address.streetAddress && (
            <span className="block">{address.streetAddress}</span>
          )}
          {address.addressLocality && (
            <span className="block">{address.addressLocality}</span>
          )}
          {address.addressRegion && (
            <span className="block">{address.addressRegion}</span>
          )}
          {address.postalCode && (
            <span className="block">{address.postalCode}</span>
          )}
          {address.addressCountry && (
            <span className="block">{address.addressCountry}</span>
          )}
        </address>
      )}
      <div className="flex flex-col gap-3">
        {phone && (
          <a
            className="roll roll-white hover:roll-activate block"
            href={`tel:${phone.replace('(0)', '').replace(/\s/g, '')}`}
          >
            {phone}
          </a>
        )}
        {email && (
          <a
            href={`mailto:${email}`}
            className="roll roll-white hover:roll-activate block"
          >
            {email}
          </a>
        )}
      </div>
    </div>
  );
}
