import clsx from 'clsx';
import {Heading, Link} from '~/components';
import {SanityLink, SanityMenu} from '~/types';

export function FooterMenu({
  menu,
  className = '',
}: {
  menu?: SanityMenu;
  className?: string;
}) {
  return (
    <div className={clsx('flex flex-col gap-5 text-white md:gap-7', className)}>
      {menu?.title && (
        <Heading as="h3" size="copy">
          {menu?.title}
        </Heading>
      )}
      <nav className="flex flex-col items-start gap-3 text-p4 uppercase md:gap-4">
        {(menu?.links || []).map((item: SanityLink) => (
          <Link
            key={item._key}
            to={item.to}
            target={item.target}
            prefetch="intent"
          >
            <span className="roll roll-white hover:roll-activate block">
              {item.title}
            </span>
          </Link>
        ))}
      </nav>
    </div>
  );
}
